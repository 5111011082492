import AsyncStorage from '@react-native-async-storage/async-storage';
import { useStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

import { queryClient } from '@services/queryClient';

type IAuth = {
  token: string | undefined;
  refreshToken: string | undefined;
  schema: string | undefined;
  onLoginSuccess(token: string, refreshToken: string, schema: string): void;
  onRefreshToken(token: string): void;
  onOutLogin(): void;
};

export const authStore = createStore(
  persist<IAuth>(
    set => ({
      token: undefined,
      schema: undefined,
      refreshToken: undefined,
      companyId: undefined,
      onLoginSuccess(token, refreshToken, schema) {
        queryClient.invalidateQueries();
        set({ token, refreshToken, schema });
      },
      onRefreshToken(token,) {
        set({ token, });
      },
      onOutLogin() {
        set({
          token: undefined,
          refreshToken: undefined,
          schema: undefined,
        });
      },
    }),
    {
      name: 'auth-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);


export function useAuthStore() {
  return useStore(authStore);
}
