import {
  Flex,
  Button,
  Stack,
  Text,
  ScrollView,
  Box,
  FlatList, Image
} from 'native-base';
import React, { useRef, useState } from 'react';
import { Pressable } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import Icons from '@expo/vector-icons/MaterialCommunityIcons';

import { RouteAppProps } from '@routes/types';

import { useAllInfos } from '@hooks/configs/useAllInfo';
import { useItem } from '@hooks/items/useItem';

import { height, isLandscape, width } from '@utils/dimensions';

import { Layout } from '@components/Layout';
import { ScreeLayout } from '@components/ScreeLayout';
import { time1Second } from '@utils/times';

export function Info({ navigation, route }: RouteAppProps<'Info'>) {
  const { colors, styles } = useAllInfos();
  const { data, } = useItem(route.params.id);

  const r = useRef<ICarouselInstance>(null);

  const [openImg, setOpenImg] = useState(false);
  const [openImgMain, setOpenImgMain] = useState(false);

  function onOpenImg(index?: number) {
    if (!openImg) {
      setOpenImg(true);
      if (index) setTimeout(() => r.current?.scrollTo({ index }), 500);
    }
  }
  function onOpenImgMain() {
    if (!openImgMain && data?.fileUrl) {
      setOpenImgMain(true);
    }
  }

  function onCloseImg() {
    if (openImg) setOpenImg(false);
  }
  function onCloseImgMain() {
    if (openImgMain) setOpenImgMain(false);
  }

  function onBack() {
    navigation.goBack()
  }

  return (
    <Layout
      onAction={isActive => {
        !isActive && onBack();
      }}
      timeForInactivity={
        data?.inactiveActiveMin && data.inactiveActiveMin * time1Second
      }
    >
      <ScreeLayout
        itemFist={
          <Stack>
            <Flex
              borderRadius="2xl"
              bg={colors?.secondaryColor || 'gray.800'}
            >
              <Pressable onPress={() => onOpenImgMain()}>
                <Box h="64" borderRadius="2xl">
                  {data?.fileUrl && <Image
                    source={{ uri: data?.fileUrl }}
                    alt={data?.title}
                    style={{ height: '100%', borderRadius: 15 }}
                  />}
                </Box>
              </Pressable>
              <Text
                bg={colors?.tertiaryColor || 'darkBlue.800'}
                color={colors?.textColorButton || 'white'}
                fontSize={styles?.titleFontSize || undefined}
                lineHeight='sm'
                py="2"
                fontWeight="bold"
                position="absolute"
                bottom="0"
                w="100%"
                borderBottomRadius="xl"
                textAlign="center">
                {data?.title}
              </Text>
            </Flex>
            <Button
              h="10"
              w="100%"
              borderRadius="xl"
              py="0"
              _text={{
                fontWeight: 'bold',
                color: colors?.textColorButton || undefined,
                fontSize: styles?.titleFontSize || undefined
              }}
              bg={colors?.tertiaryColor || 'darkBlue.800'}
              _hover={{ bg: colors?.tertiaryColor || 'darkBlue.800' }}
              colorScheme="black"
              _pressed={{ opacity: 0.5 }}
              onPress={onBack}>
              Voltar
            </Button>
          </Stack>
        }
        itemSecond={
          <Flex>
            <Box
              h="10"
              w="104%"
              right="0"
              position="absolute"
              top="0"
              bg={{
                linearGradient: {
                  colors: ['gray.800', '#595959F0', '#59595955', '#5959590a'],
                },
              }}
              zIndex={1}
            />
            <ScrollView
              pt="10"
              pb="6"
              mb="2"
              showsVerticalScrollIndicator={false}>
              <Text
                color="black"
                fontSize={styles?.titleFontSize || "xl"}
                fontFamily="Inter"
                textAlign="justify"
                lineHeight="lg">
                {data?.description}
              </Text>
              <Box h={data?.files?.length ? 190 : '12'} />
            </ScrollView>
          </Flex>
        }
      />
      {openImgMain && (
        <Box position="absolute" h="100%" w="100%" zIndex={1}>
          <Pressable
            onPress={onCloseImgMain}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 1,
            }}>
            <Icons
              name="close"
              color={colors?.textColorButton || 'white'}
              size={45}
              style={{
                backgroundColor: colors?.tertiaryColor || 'black',
                borderRadius: 30,
                padding: 2,
              }}
            />
          </Pressable>
          <Image
            source={{ uri: data?.fileUrl }}
            style={{ height: '100%' }}
            resizeMode="contain"
            alt='Imagem principal'
          />
        </Box>
      )}
      {data?.files && data.files.length > 0 && (
        <Box
          w="100%"
          position="absolute"
          bottom="0"
          pb="2"
          h={openImg ? '100%' : 'auto'}
          justifyContent="flex-end"
          zIndex="2">
          {openImg && (
            <Pressable
              onPress={onCloseImg}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1,
              }}>
              <Icons
                name="close"
                color={colors?.textColorButton || 'white'}
                size={45}
                style={{
                  backgroundColor: colors?.tertiaryColor || 'black',
                  borderRadius: 30,
                }}
              />
            </Pressable>
          )}
          {data.files.length < 11 ? (
            <GestureHandlerRootView>
              <Carousel
                ref={r}
                loop
                autoPlay={!openImg}
                autoPlayInterval={2000}
                style={{ width: '100%' }}
                width={openImg ? width : 300}
                height={openImg ? height : 160}
                data={data.files}
                renderItem={({ item, index }) => (
                  <Pressable
                    key={item.id}
                    onPress={() => onOpenImg(index)}
                    style={{ flex: 1 }}>
                    <Image
                      source={{ uri: item.url }}
                      style={{ height: '100%' }}
                      alt={item.id}
                      resizeMode="contain"
                    />
                  </Pressable>
                )}
              />
            </GestureHandlerRootView>
          ) : (
            <FlatList
              data={data.files}
              horizontal
              style={{
                height: openImg ? height : 160,
                paddingVertical: openImg ? 20 : 0,
              }}
              showsHorizontalScrollIndicator={false}
              renderItem={({ item }) => (
                <Pressable
                  onPress={() => onOpenImg()}
                  style={{ flex: 1, width: openImg ? width : 300 }}>
                  <Image
                    source={{ uri: item.url }}
                    style={{ height: '100%' }}
                    alt={item.id}
                    resizeMode="contain"
                  />
                </Pressable>
              )}
              keyExtractor={item => item.id}
            />
          )}
        </Box>
      )}
    </Layout>
  );
}
