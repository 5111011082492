import { useMutation } from '@tanstack/react-query';

import { useAuthStore } from '@stores/useAuthStore';

import { api } from '@services/api';
import { AppError } from '@services/errors/AppErro';

import { showToast } from '@utils/showToast';

interface IProps {
  email: string;
  password: string;
  schema: string;
}

interface IResponse {
  token: string;
  refresh_token: string;
}

export function useSingIn() {
  const { onLoginSuccess } = useAuthStore();

  return useMutation(
    async ({ schema, ...rest }: IProps) => {
      const { data } = await api.post<IResponse>(`/${schema}/auth/login`, rest);

      return data;
    },
    {
      onSuccess({ token, refresh_token }, { schema }) {
        onLoginSuccess(token, refresh_token, schema);
        api.defaults.headers.Authorization = `Bearer ${token}`;
      },
      onError(err: AppError) {
        console.log(err);
        showToast({
          title: 'Erro ao fazer login!',
          message: 'Verifique seu e-mail e senha',
          type: 'error',
        });
      },
    },
  );
}
