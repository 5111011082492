import { Box, Button, Center, HStack, Heading, Modal, PinInput, Stack } from 'native-base';
import React, { useState } from 'react';

import { useMenuStore } from '@stores/useMenu';

import { SetPrint } from './SetPrint';
import { useAllInfos } from '@hooks/configs/useAllInfo';
import { showToast } from '@utils/showToast';
import { authStore } from '@stores/useAuthStore';
import { About } from './About';
import { queryClient } from '@services/queryClient';
import { SetLayout } from './SetLayout';

const items = {
  about: { View: <About /> },
  Print: { View: <SetPrint /> },
  Layout: { View: <SetLayout /> },
};

export function Menu() {
  const { isOpen, onClose } = useMenuStore();

  const [pin, setPin] = useState('');
  const [isCorrectPin, setIsCorrectPin] = useState(false);
  const [show, setShow] = useState<'about' | 'Print' | 'Layout'>();


  const { infos } = useAllInfos();

  function changePin(value: string) {
    setPin(s => (s.length < 4 ? `${s}${value}` : s));
  }

  function handleVerifyPin() {
    if (pin === (infos?.pin || '1234')) {
      setIsCorrectPin(true);
    } else {
      showToast({
        title: 'Senha incorreta',
        message: 'Senha incorreta, tente novamente',
        type: 'error',
      });
      setPin('');
    }
  }

  function handleClose() {
    setPin('');
    setIsCorrectPin(false);
    setShow(undefined);
    onClose();
  }

  function Items() {
    return (
      <Stack space="4">
        <Button onPress={() => window?.document?.documentElement?.requestFullscreen()} borderRadius="full">
          Tela Full Screen
        </Button>
        <Button onPress={() => window?.document?.exitFullscreen()}
          borderRadius="full">
          Sair Full Screen
        </Button>
        <Button onPress={() => setShow('Print')} borderRadius="full">
          Configurar impressora
        </Button>
        <Button onPress={() => setShow('Layout')} borderRadius="full">
          Configurar Layout
        </Button>
        <Button
          onPress={() => queryClient.invalidateQueries()}
          borderRadius="full">
          Atualizar dados
        </Button>
        <Button
          onPress={() => authStore.getState().onOutLogin()}
          borderRadius="full">
          Logout
        </Button>
        <Button onPress={() => setShow('about')} borderRadius="full">
          Sobre
        </Button>
      </Stack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <Modal.Content w="90%">
        <Modal.CloseButton />
        <Modal.Header>
          <Heading>Menu</Heading>
        </Modal.Header>
        <Modal.Body>
          {isCorrectPin ? (
            show ? (
              items[show].View
            ) : (
              <Items />
            )
          ) : (
            <Stack space="4" mt="8">
              <HStack space="4" justifyContent="center">
                <PinInput
                  bg="darkBlue.800"
                  color="white"
                  maxLength={1}
                  value={pin}
                  placeholder='*'
                  editable={false}
                >
                  <PinInput.Field />
                  <PinInput.Field />
                  <PinInput.Field />
                  <PinInput.Field />
                </PinInput>
              </HStack>
              <Center>
                <Box rounded="lg" bg="gray.200" p="2">
                  <HStack space="3">
                    <Button onPress={() => changePin('0')}>0</Button>
                    <Button onPress={() => changePin('1')}>1</Button>
                    <Button onPress={() => changePin('2')}>2</Button>
                    <Button onPress={() => changePin('3')}>3</Button>
                    <Button onPress={() => changePin('4')}>4</Button>
                  </HStack>
                  <HStack space="3" mt="3">
                    <Button onPress={() => changePin('5')}>5</Button>
                    <Button onPress={() => changePin('6')}>6</Button>
                    <Button onPress={() => changePin('7')}>7</Button>
                    <Button onPress={() => changePin('8')}>8</Button>
                    <Button onPress={() => changePin('9')}>9</Button>
                  </HStack>
                  <HStack space="3" mt="8" ml="auto">
                    <Button onPress={() => setPin('')} colorScheme="danger">
                      Limpar
                    </Button>
                    <Button onPress={handleVerifyPin} colorScheme="success">
                      Confirmar
                    </Button>
                  </HStack>
                </Box>
              </Center>
            </Stack>
          )}
        </Modal.Body>
        <Modal.Footer>
          {show && <Button onPress={() => setShow(undefined)} colorScheme="blueGray" px="12">
            Voltar
          </Button>}
          <Button onPress={handleClose} colorScheme="danger" px="12" ml='4'>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal >
  );
}
