import React from 'react';

import { IInput } from '@interfaces/IInput';

import { InputArea } from './InputArea';
import { InputCheckbox } from './InputCheckbox';
import { InputDate } from './InputDate';
import { InputMultipleSelect } from './InputMultipleSelect';
import { InputNumber } from './InputNumber';
import { InputRadio } from './InputRadio';
import { InputSelect } from './InputSelect';
import { InputText } from './InputText';
import { InputTime } from './InputTime';

export function InputForm({ ...item }: IInput) {
  switch (item.type) {
    case 'Text':
      return <InputText {...item} />;
    case 'Textarea':
      return <InputArea {...item} />;
    case 'Number':
      return <InputNumber {...item} />;
    case 'Select':
      return <InputSelect {...item} />;
    case 'Multiple Select':
      return <InputMultipleSelect {...item} />;
    case 'Checkbox':
      return <InputCheckbox {...item} />;
    case 'Radio':
      return <InputRadio {...item} />;
    case 'Date':
      return <InputDate {...item} />;
    case 'Time':
      return <InputTime {...item} />;
    default:
      return null;
  }
}
