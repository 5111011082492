import { useMutation } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IError } from '@interfaces/IResponse';
import axios from 'axios';
import { PrintStore } from '@stores/usePrintStore';
import { getCurrentDate, getCurrentTime2 } from '@utils/getCurrentTime';

interface IProps {
  queueTypeId: string;
  queuePasswordTypeId: string;
}

interface ISuccess {
  id: string;
  key: number;
  initial: string;
  final: string;
  name: string;
  type: string;
  createdAt: Date;
  header?: string;
  text1?: string;
  text2?: string;
  subtitle?: string;
}

async function callApi(item: IProps) {
  const { data } = await api.post(
    `/${authStore.getState().schema}/queue/key`,
    item,
  );

  return data.payload;
}

function onError(error: IError) {
  console.log(JSON.stringify(error));
}

function onSuccess({
  key,
  type,
  name,
  initial,
  final,
  header,
  text1,
  text2
}: ISuccess) {
  try {
    const { print, typePrint } = PrintStore.getState();

    let senha = '';
    if (initial) senha += `${initial}-`;
    senha += key;
    if (final) senha += `-${final}`;

    axios.get('http://localhost/POS-PHP/print_1.php', {
      params: {
        fila: name,
        senha,
        tipo: type,
        texto0: header || 'Gestão Centra',
        texto3: 'Tenha um ótimo dia!',
        data: getCurrentDate(),
        impressora: print,
        fuso: '3',
        tipoimpressao: typePrint,
        texto1: text1,
        texto2: text2,
      },
      headers: { 'Access-Control-Allow-Origin': 'http://localhost/POS-PHP/print_1.php' }
    })
  } catch (e: any) {
    console.log(e?.response?.data);
  }
}

export function useCreateQueueKey() {
  return useMutation(callApi, { onError, onSuccess });
}
