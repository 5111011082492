import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IQueue } from '@interfaces/IQueue';
import { IError, ISuccess } from '@interfaces/IResponse';

async function list(id: string): Promise<IQueue> {
  const { data } = await api.get<ISuccess<IQueue>>(
    `/${authStore.getState().schema}/queue-types/${id}`,
  );

  return data.payload;
}

export function useQueue(id: string) {
  return useQuery<IQueue, IError>(['Queue', id], () => list(id), {
    staleTime: 1000 * 60 * 10,
  });
}
