import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';

import { RouteAppProps } from '@routes/types';

import { socketIo } from '@services/io';

import { useAllInfos } from '@hooks/configs/useAllInfo';
import { useAllFiles } from '@hooks/items/useAllFiles';

import { useItems } from '@hooks/items/useItems';
import { useQueues } from '@hooks/queues/useQueues';

import { filter, types } from '@utils/homeUtils';
import { time1Second } from '@utils/times';


import { Layout } from '@components/Layout';
import { ShowInactivity } from '@components/ShowInactivity';

import { ICard } from '@interfaces/ICard';
import { useNpsItems } from '@hooks/nps/useNpsItems';

import { HomeScreen2 } from '@components/Home/HomeScreen2';
import { useLayoutStore } from '@stores/useLayoutStore';
import { HomeScreen } from '@components/Home/HomeScreen';
import { useVideos } from '@hooks/videos/useVideos';
import { useNewEvent } from '@hooks/metric/useNewEvent';

export function Home({ navigation: { navigate } }: RouteAppProps<'Home'>) {
  const { data } = useItems();
  const { data: dataQueue } = useQueues();
  const { data: allFiles } = useAllFiles();
  const { data: dataNps } = useNpsItems();
  const { data: dataVideos } = useVideos();


  const [active, setActive] = useState(true);

  const { user } = useAllInfos();

  const isFocused = useIsFocused();
  const onNewEvent = useNewEvent();

  const { typeLayout } = useLayoutStore();

  const items: ICard[] = useMemo(() => {
    if (user?.type === 'queue-only') return dataQueue || [];

    const itemsSelect =
      data?.filter(item => {
        return types.includes(item?.type) && item?.showHome;
      }) || [];

    const videos = dataVideos?.filter(i => i.showHome && i.fileUrl) || [];

    const queues = dataQueue || [];
    const nps = dataNps || [];
    const allItems: ICard[] = [...itemsSelect, ...videos, ...queues, ...nps];

    return allItems;
  }, [data, dataQueue, dataNps, user, dataVideos]);

  const itemsInactivity = useMemo(() => {
    if (!user) return [];

    const newItems =
      data?.filter(
        item => types.includes(item.type) && item.isInactive && item.fileUrl,
      ) || [];

    const videos = dataVideos?.filter(i => i.isInactive && i.fileUrl) || [];

    return filter([...newItems, ...videos], user.type, allFiles);
  }, [user, items, allFiles, dataVideos]);

  useEffect(() => {
    socketIo();
  }, []);

  function onPressItem(item: ICard) {
    const { type, id } = item;

    onNewEvent.mutate({ type: 'impression', itemId: id, subType: type });

    if (type === 'Form') navigate('Form', { id });
    else if (type === 'Queue') navigate('Queue', { id });
    else if (type === 'NPS') navigate('Nps', { id });
    if (type === 'Info') navigate('Info', { id });
    else if (type === 'Video') {
      navigate('Video', {
        id,
        title: String(item.title),
        fileUrl: String(item.fileUrl),
        description: item.description,
      });
    }
  }

  function onActionTime(isActive: boolean) {
    setActive(isActive);
  }

  return (
    <Layout
      onAction={onActionTime}
      timeForInactivity={user?.timeInactiveSec ? user.timeInactiveSec * time1Second : undefined}
      isActive={isFocused}>
      {
        typeLayout === 'type1'
          ? <HomeScreen items={items} onPressItem={onPressItem} />
          : <HomeScreen2 items={items} onPressItem={onPressItem} />
      }
      <ShowInactivity
        type={user?.type}
        items={itemsInactivity}
        isActive={!active && user?.showInactive && isFocused}
      />
    </Layout>
  );
}
