import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IQueue } from '@interfaces/IQueue';
import { IError, ISuccess } from '@interfaces/IResponse';
import { IPagination } from '@interfaces/pagination';

async function list(): Promise<IQueue[]> {
  const { data } = await api.get<ISuccess<IPagination<IQueue>>>(
    `/${authStore.getState().schema}/queue-types`,
    { params: { disabled: false, limit: 100, show: true } },
  );

  return data?.payload?.data?.map(item => ({ ...item, type: 'Queue' })) || [];
}

export function useQueues() {
  return useQuery<IQueue[], IError>(['Queue-Type'], () => list(), {
    staleTime: 1000 * 60 * 10,
  });
}
