import Easy from './src';

export default function App() {
  return (
    <Easy />
  );
}



