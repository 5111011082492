import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IQueuePasswordType } from '@interfaces/IQueuePasswordType';
import { IError, ISuccess } from '@interfaces/IResponse';
import { IPagination } from '@interfaces/pagination';

async function list(id?: string): Promise<IQueuePasswordType[]> {
  if (id) {
    const { data } = await api.get<ISuccess<IQueuePasswordType>>(
      `/${authStore.getState().schema}/queue-password-type/${id}`,
    );

    return data.payload?.queuePasswordTypes as [];
  }

  const { data } = await api.get<ISuccess<IPagination<IQueuePasswordType>>>(
    `/${authStore.getState().schema}/queue-password-type`,
    { params: { disabled: false, limit: 100, show: true } },
  );

  return data.payload.data;
}

export function useQueuePasswordTypes(id?: string) {
  return useQuery<IQueuePasswordType[], IError>(
    ['Queue-Password-Types', id],
    () => list(id),
    { staleTime: 1000 * 60 * 10 },
  );
}
