import { NavigationContainer } from '@react-navigation/native';
import { QueryClientProvider } from '@tanstack/react-query';
import { NativeBaseProvider } from 'native-base';
import React from 'react';
import { LogBox, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Toast from 'react-native-toast-message';

import { Routes } from '@routes/index.routes';

import { queryClient } from '@services/queryClient';

import { Menu } from '@components/Menu';

import theme from './themes';

const config = { dependencies: { 'linear-gradient': LinearGradient } };

function App() {
  LogBox.ignoreAllLogs();

  return (
    <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.89)' }} >
      <QueryClientProvider client={queryClient} >
        <NativeBaseProvider theme={theme} config={config}>
          <NavigationContainer>
            <Routes />
            <Toast />
            <Menu />
          </NavigationContainer>
        </NativeBaseProvider>
      </QueryClientProvider>
    </View>
  );
}


export default App;
