import { Card2 } from "@components/Card2"
import { ICard } from "@interfaces/ICard";
import { useLayoutStore } from "@stores/useLayoutStore";
import { Box, Center, FlatList } from "native-base"

type IProps = {
  items: ICard[]
  onPressItem: (i: ICard) => void;
}

export function HomeScreen2({ items, onPressItem }: IProps) {

  const { columns, height, rounded, width } = useLayoutStore();


  return (
    <Center w="100%" h='88%'>
      <FlatList
        data={items}
        showsVerticalScrollIndicator={false}
        numColumns={columns}
        keyExtractor={item => item.id}
        renderItem={({ item }) => (
          <Card2
            title={String(item.title || item.name)}
            fileUrl={item.fileUrl}
            type={item.type}
            onPressItem={() => onPressItem(item)}
            w={width}
            h={height}
            rounded={rounded}
            cardUrl={item.cardUrl}
          />
        )}
        flex={1}
        ListFooterComponent={() => <Box h="32" />}
        ItemSeparatorComponent={() => <Box h="15" />}
      />
    </Center>
  )
}