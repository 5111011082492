import {
  Stack,
  Button,
  KeyboardAvoidingView,
  Input,
  Flex,
  useColorMode,
  Icon,
  View,
  FlatList,
  Box,
  HStack,
  Text,
  Skeleton,
} from 'native-base';
import React, { useState } from 'react';
import { Pressable, TouchableOpacity } from 'react-native';
import { RouteAuthProps } from '@routes/types';

import { useCompanies } from '@hooks/companies/useCompanies';

import useDebounce from '@utils/useDebounce';

export function SelectCompany({ navigation }: RouteAuthProps<'SelectCompany'>) {
  const [nameCompany, sendName] = useState('');


  const nameDebounce = useDebounce(sendName);

  async function onSelect(name: string, schema: string) {
    navigation.navigate('SignIn', { name, schema });
  }
  const { data, isLoading, isRefetching } = useCompanies({ name: nameCompany });

  return (
    <View bg='darkBlue.900' flex="1">
      <Pressable>
        <KeyboardAvoidingView>
          <Flex align="center" pt="24" paddingBottom={2}>
            <Stack w="90%" maxWidth="390px" mt="10" space="7">
              <Input
                placeholder="Empresa"
                returnKeyType="send"
                onChangeText={nameDebounce}
                defaultValue={nameCompany}
                disableFullscreenUI
                color="gray.200"
              />
              <Box>
                {(isLoading || isRefetching) && nameCompany && (
                  <Skeleton
                    h="30"
                    borderRadius="lg"
                    endColor='gray.800'
                    startColor='gray.700'
                  />
                )}
                <FlatList
                  mt="5"
                  data={data?.data || []}
                  keyExtractor={item => item.id}
                  ItemSeparatorComponent={() => <Box h="10" />}
                  renderItem={({ item }) => (
                    <Box
                      borderBottomWidth="1"
                      borderTopWidth="0"
                      borderColor='gray.600'
                      pl="4"
                      pr="5"
                      py="2">
                      <TouchableOpacity
                        onPress={() => onSelect(item.name, item.schema)}
                        style={{ width: '100%' }}>
                        <HStack
                          alignItems="center"
                          justifyContent="space-between">
                          <Text fontWeight="bold" fontSize={18}>
                            {item.name}
                          </Text>
                        </HStack>
                      </TouchableOpacity>
                    </Box>
                  )}
                />
              </Box>
            </Stack>
          </Flex>
        </KeyboardAvoidingView>
      </Pressable>
    </View>
  );
}
