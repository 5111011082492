import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { formatDate } from '@utils/formateDate';
import { time60Seconds } from '@utils/times';

import { IItem } from '@interfaces/IItem';
import { IError, ISuccess } from '@interfaces/IResponse';

async function list(id: string): Promise<IItem> {
  const { data } = await api.get<ISuccess<IItem>>(
    `/${authStore.getState().schema}/items/${id}`,
  );

  return {
    ...data.payload,
    inactiveActiveMin:
      data.payload?.inactiveActiveMin &&
      data.payload.inactiveActiveMin * time60Seconds,
    startIn: data.payload.startIn && formatDate(data.payload.startIn),
    endIn: data.payload.endIn && formatDate(data.payload.endIn),
  };
}

export function useItem(item: string) {
  return useQuery<IItem, IError>(['Item', item], () => list(item), {
    staleTime: 1000 * 60 * 10,
  });
}
