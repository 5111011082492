import {
  CheckIcon,
  FormControl,
  Select,
  Stack,
  WarningOutlineIcon,
} from 'native-base';
import React, { useMemo } from 'react';

import { useResponseStore } from '@stores/useResponseStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

import useDebounce from '@utils/useDebounce';

interface IProps {
  id: string;
  name: string;
  required: boolean;
  options: string[];
}

export function InputSelect({ id, name, required, options }: IProps) {
  const { items, onChangeValue, isCheck } = useResponseStore();

  const { colors } = useAllInfos();

  function onChange(value: string | null) {
    onChangeValue(id, value);
  }

  const item = useMemo(() => {
    return items.find(i => i.inputId === id);
  }, [items.length, id]);

  const isValidMessage = useMemo(() => {
    if (!isCheck) return undefined;

    if (required && !item?.value) {
      return 'Campo obrigatório';
    }

    return false;
  }, [item, isCheck]);

  return (
    <FormControl isRequired={required} isInvalid={!!isValidMessage}>
      <Stack mx="4">
        <FormControl.Label
          _text={{ color: colors?.textColorSimple || 'gray.300' }}>
          {name}
        </FormControl.Label>
        <Select
          key={id}
          minWidth="600"
          accessibilityLabel="Choose Service"
          _selectedItem={{
            bg: 'teal.600',
            endIcon: <CheckIcon size="5" />,
          }}
          onValueChange={onChange}
          defaultValue={item?.value}>
          {options.map(option => (
            <Select.Item key={option} label={option} value={option} />
          ))}
        </Select>
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {isValidMessage}
        </FormControl.ErrorMessage>
      </Stack>
    </FormControl>
  );
}
