import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IItem } from '@interfaces/IItem';
import { IError, ISuccess } from '@interfaces/IResponse';
import { IPagination } from '@interfaces/pagination';

interface IRequest {
  limit?: number;
  page?: number;
  name?: string;
}

async function list(params: IRequest): Promise<IItem[]> {
  const { data } = await api.get<ISuccess<IPagination<IItem>>>(
    `/${authStore.getState().schema}/items`,
    { params: { ...params, disabled: false, limit: 100 } },
  );

  return data.payload.data;
}

export function useItems(params: IRequest = {}) {
  return useQuery<IItem[], IError>(['Items', params], () => list(params), {
    staleTime: 1000 * 60 * 10,
  });
}
