import { Heading, Stack } from 'native-base';


export function About() {
  return (
    <Stack space="4" alignItems="center">
      <Heading>Informações</Heading>
      <Heading size="sm">Nome: Easy Link Web</Heading>
      <Heading size="sm">Empresa: Ideploy</Heading>
      <Heading size="sm">Versão: 1.6.4</Heading>
      <Heading size="sm">Release: 164-2W</Heading>
      <Heading size="sm">Data: 21/11/2023</Heading>
      <Heading size="sm">Autor: Equipe Ideploy</Heading>
    </Stack>
  );
}
