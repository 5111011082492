import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  Select,
  Stack,
  Text,
} from 'native-base';
import React, { useCallback, useRef, } from 'react';

import { usePrintStore } from '@stores/usePrintStore';
import { showToast } from '@utils/showToast';

export function SetPrint() {
  const { onPrint, print, typePrint } = usePrintStore();

  const printRef = useRef<any>(null);
  const printTypeRef = useRef<any>(null);

  const handleSelectPrint = useCallback(
    () => {
      const print = printRef.current.value;
      const printType = printTypeRef.current.value;
      if (!print) {
        showToast({
          title: 'Impressora não selecionada',
          type: 'error',
          message: 'Informe a impressora que deseja utilizar'
        })
        printRef.current.focus();
        return;
      }

      onPrint(print, printType);
      showToast({
        title: 'Impressora selecionada',
        message: 'Impressora selecionada com sucesso'
      })
    },
    [],
  );

  return (
    <Box>
      <Text
        textAlign="justify"
        color="black"
        fontSize="xl"
        lineHeight="40">
        Configurações de impressão
      </Text>
      <Stack mt='8' space='10' >
        <FormControl >
          <FormControl.Label>
            Impressora
          </FormControl.Label>
          <Input
            placeholder="Impressora"
            autoComplete='off'
            returnKeyType="done"
            ref={printRef}
            defaultValue={print}
            disableFullscreenUI
            bg='gray.500'
            rounded='md'
          />
        </FormControl>
        <FormControl>
          <FormControl.Label>
            Tipo de impressora
          </FormControl.Label>
          <Select defaultValue={typePrint} ref={printTypeRef}
            bg='gray.500'
            rounded='md'
          >
            <Select.Item label="Windows" value="windows" />
            <Select.Item label="Linux" value="linux" />
          </Select>
        </FormControl>
      </Stack>
      <HStack mt='8' justifyContent='end'>
        <Button onPress={handleSelectPrint} colorScheme="success" px="12">
          Salvar
        </Button>
      </HStack>
    </Box>
  );
}
