import { extendTheme } from 'native-base';

export default extendTheme<any>({
  fontConfig: {
    Inter: {
      300: { normal: 'Inter-Light' },
      400: { normal: 'Inter-Regular' },
      500: { normal: 'Inter-Medium' },
      700: { normal: 'Inter-Bold' },
      800: { normal: 'Inter-ExtraBold' },
      900: { normal: 'Inter-Black' },
      light: { normal: 'Inter-Light' },
      thin: { normal: 'Inter-Thin' },
      normal: { normal: 'Inter-Regular' },
      medium: { normal: 'Inter-Medium' },
      semi: { normal: 'Inter-SemiBold' },
      bold: { normal: 'Inter-Bold' },
      extrabold: { normal: 'Inter-ExtraBold' },
      black: { normal: 'Inter-Black' },
    },
  },
  fonts: { heading: 'Inter', body: 'Inter', mono: 'Inter' },
  colors: {
    gray: {
      200: '#E5E5E5',
      300: '#F3F3F3',
      500: '#C4C4C4',
      700: '#515151',
      800: '#595959',
      900: '#292929',
    },
    red: { 600: '#D43A3F' },
    dark: { 50: '#18181b', 100: '#1E1E1E', 500: '#A9A9A9' },
    success: { 600: '#62A851' },
  },
  components: {
    Input: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        placeholderTextColor: '#F7F7F7',
        paddingLeft: '3',
        autoCapitalize: 'none',
        fontFamily: 'Inter',
      }),
      defaultProps: {
        _focus: { borderColor: '#f7f7f7' },
        variant: 'underlined',
        fontSize: '16',
        borderBottomWidth: '1.2px',
        _dark: { borderColor: '#505050', _focus: { borderColor: '#F7F7F7' } },
        _light: { borderColor: '#f7f7f7', _focus: { borderColor: '#505050' } },
      },
    },
    Button: {
      baseStyle: () => ({ borderRadius: '6' }),
      defaultProps: {
        size: 'lg',
        _text: { fontWeight: 500, color: '#F3F3F3' },
      },
    },
    VStack: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        bg: colorMode === 'dark' ? 'dark.100' : 'white',
        rounded: 'xl',
        padding: '4',
      }),
    },
    Divider: { baseStyle: { bg: '#D43A3F', mt: '6px' } },
    ModalContent: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        bg: colorMode === 'dark' ? 'dark.100' : 'trueGray.100',
      }),
    },
    ModalFooter: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        bg: colorMode === 'dark' ? 'dark.100' : 'trueGray.100',
      }),
    },
    Text: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        color: 'dark.500',
        fontSize: '15',
        fontWeight: 300,
        lineHeight: '17px',
      }),
    },
  },
});
