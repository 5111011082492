import { Box, Heading, HStack, Image } from 'native-base';
import React, { ReactNode } from 'react';
import { ImageBackground, Pressable } from 'react-native';
import UserInactivity from 'react-native-user-inactivity';
import Icons from '@expo/vector-icons/FontAwesome5';

import { useMenuStore } from '@stores/useMenu';
import { useTimeStore } from '@stores/useTimeStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

import { time5Minutes } from '@utils/times';

interface IProps {
  children: ReactNode;
  onAction?: (i: boolean) => void;
  timeForInactivity?: number;
  isActive?: boolean;
}

const gradient = ['rgba(0, 0, 0, 0.89)', 'rgba(0,0, 0, 0.77)'];

let menuClick = 0;
let menuTimeout: NodeJS.Timeout;

export function Layout({
  children,
  onAction,
  timeForInactivity,
  isActive,
}: IProps) {
  const { colors, infos } = useAllInfos();

  const { time } = useTimeStore();
  const { onOpen } = useMenuStore();

  function handleClick() {
    if (!menuTimeout) menuTimeout = setTimeout(() => menuClick = 0, 10000);

    menuClick += 1;

    if (menuClick === 3) onOpen();
  }

  return (
    <UserInactivity
      isActive={isActive}
      timeForInactivity={timeForInactivity || time5Minutes}
      onAction={isAct => {
        if (onAction) onAction(isAct);
      }}>
      <Box
        h="100%"
        w="100%"
        bg={
          colors?.primaryColor ||
          { linearGradient: { colors: gradient, start: [0, 1], end: [0, 0] } }
        }
      >
        {infos?.bgImage && <Image
          source={{ uri: infos?.bgImage }}
          h="full"
          w="full"
          alt="bg"
          position="absolute"
        />}
        <HStack direction="row" space="4" py="6" px="8" alignItems="center">
          {infos?.logo ? (
            <Image
              source={{ uri: infos.logo }}
              style={{ width: 100 }}
              alt="logo"
              size="xs"
              resizeMode="contain"
              my="1"
            />
          ) : (
            <Heading
              fontSize="3xl"
              color={colors?.textColorTitle || 'gray.400'}>
              {infos?.nameApp || 'Easy Link'}
            </Heading>
          )}
          <div
            style={{ marginLeft: 'auto' }}
            // onMouseDown={handleMouseDown}
            // onMouseUp={handleMouseUp}
            onClick={handleClick}
          >
            <Heading
              fontSize="3xl"
              ml="auto"
              mr="4"
              color={colors?.textColorTitle || 'gray.400'}>
              {time || '00:00'}
            </Heading>
          </div>
        </HStack>
        {children}
      </Box>
    </UserInactivity>
  );
}
