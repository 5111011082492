import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { INps } from '@interfaces/INps';
import { IError, ISuccess } from '@interfaces/IResponse';
import { IPagination } from '@interfaces/pagination';

async function list(): Promise<INps[]> {
  const { data } = await api.get<ISuccess<IPagination<INps>>>(
    `/${authStore.getState().schema}/nps/item`,
    { params: { disabled: false, limit: 100 } },
  );

  return data?.payload?.data?.map(item => ({ ...item, type: 'NPS' })) || [];
}

export function useNpsItems() {
  return useQuery<INps[], IError>(['nps-item'], () => list(), {
    staleTime: 1000 * 60 * 10,
  });
}
