import AsyncStorage from '@react-native-async-storage/async-storage';
import { create, useStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

import { queryClient } from '@services/queryClient';


type IPrint = {
  print: string;
  typePrint: 'linux' | 'windows';
  onPrint(print: string, typePrint: 'linux' | 'windows'): void;
};

export const PrintStore = createStore(
  persist<IPrint>(
    set => ({
      print: '',
      typePrint: 'windows',
      onPrint(print, typePrint) {
        set({ print, typePrint });
      }
    }),
    {
      name: 'print-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export function usePrintStore() {
  return useStore(PrintStore);
}
