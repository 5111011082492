import { FormControl, Input, Stack, WarningOutlineIcon } from 'native-base';
import React, { useMemo, useRef } from 'react';
import { TextInput } from 'react-native';
import { toPattern } from 'vanilla-masker';

import { useResponseStore } from '@stores/useResponseStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

import useDebounce from '@utils/useDebounce';

interface IProps {
  id: string;
  name: string;
  required: boolean;
}

export function InputDate({ id, name, required }: IProps) {
  const inputRef = useRef<TextInput>();
  const { items, isCheck, onChangeValue } = useResponseStore();
  const valueDebounce = useDebounce(onChangeValue);
  const { colors } = useAllInfos();

  const regDate =
    /(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(19[0-9]{2}|20[0-9]{2})/;

  function onChange(i: string) {
    const value = toPattern(i.replace(/\W/g, ''), '99/99/9999');
    inputRef.current?.setNativeProps({ text: value });
    valueDebounce(id, value);
  }

  const item = useMemo(() => {
    return items.find(i => i.inputId === id);
  }, [items.length, id]);

  const isValidMessage = useMemo(() => {
    if (!isCheck) return undefined;

    if (required && !item?.value) {
      return 'Campo obrigatório';
    }
    if (item?.value && !regDate.test(item?.value)) {
      return 'Data inválida';
    }

    return undefined;
  }, [item?.value, isCheck]);

  return (
    <FormControl isRequired={required} isInvalid={!!isValidMessage}>
      <Stack mx="4">
        <FormControl.Label
          _text={{ color: colors?.textColorSimple || 'gray.300' }}>
          {name}
        </FormControl.Label>
        <Input
          ref={inputRef}
          onChangeText={onChange}
          defaultValue={item?.value}
          maxLength={10}
          keyboardType="numeric"
          disableFullscreenUI
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {isValidMessage}
        </FormControl.ErrorMessage>
      </Stack>
    </FormControl>
  );
}
