import {
  Flex,
  Button,
  Stack,
  Text,
  ScrollView,
  Box,
  FlatList,
  Icon,
  HStack, Image
} from 'native-base';
import React, { useMemo, useState } from 'react';
import Icons from '@expo/vector-icons/FontAwesome5';

import { RouteAppProps } from '@routes/types';

import { useAllInfos } from '@hooks/configs/useAllInfo';
import { useQueue } from '@hooks/queues/useQueue';
import { useQueuePasswordTypes } from '@hooks/queues/useQueuePasswordTypes';

import { Layout } from '@components/Layout';
import { Loading } from '@components/Loading';
import { QueueCard } from '@components/Queue/QueueCard';
import { QueueKeyModal } from '@components/Queue/QueueKeyModal';
import { ScreeLayout } from '@components/ScreeLayout';

export function Queue({ navigation, route }: RouteAppProps<'Queue'>) {
  const { colors, styles } = useAllInfos();
  const { data, isLoading } = useQueue(route.params.id);

  const [ptId, setPtId] = useState<string>();
  const passwordTypes = useQueuePasswordTypes(ptId);


  function onBack(isBackAll = false) {
    if (data?.queueTypeId && !isBackAll) {
      navigation.navigate('Queue', { id: data.queueTypeId });
    } else {
      navigation.popToTop()
    }
  }

  function onPressItem(id: string) {
    navigation.navigate('Queue', { id });
  }

  const keyTypes = useMemo(() => {
    if (!ptId && data?.passwordTypes?.length) {
      return data.passwordTypes.map(item => item.queuePasswordType)
    }

    return passwordTypes.data || []
  }, [passwordTypes.data, data]);

  return (
    <Layout
      onAction={isActive => {
        !isActive && onBack(true);
      }}>
      <ScreeLayout
        itemFist={
          <Stack>
            <Flex borderRadius="2xl">
              {data?.fileUrl && <Box h="64" borderRadius="2xl">
                <Image
                  source={{ uri: data?.fileUrl }}
                  alt={data?.name}
                  style={{ height: '100%', borderRadius: 15 }}
                />
              </Box>}
              <Text
                py="2"
                fontWeight="bold"
                bottom="0"
                w="100%"
                borderBottomRadius={data?.fileUrl ? "xl" : '2xl'}
                mt='32'
                bg={colors?.tertiaryColor || 'darkBlue.800'}
                color={colors?.textColorButton || 'white'}
                position={data?.fileUrl ? "absolute" : undefined}
                lineHeight='md'
                fontSize={styles?.titleFontSize || undefined}
                borderTopRadius={data?.fileUrl ? undefined : '2xl'}
                textAlign="center">
                {data?.name}
              </Text>
            </Flex>
            <Button
              w="100%"
              borderRadius="xl"
              py="2"
              _text={{
                fontWeight: 'bold',
                fontSize: styles?.titleFontSize || undefined,
                color: colors?.textColorButton || undefined,
              }}
              bg={colors?.tertiaryColor || 'darkBlue.800'}
              _hover={{ bg: colors?.tertiaryColor || 'darkBlue.800' }}
              colorScheme="black"
              _pressed={{ opacity: 0.5 }}
              onPress={() => onBack()}>
              Voltar
            </Button>
          </Stack>
        }
        itemSecond={
          < Flex
            style={{ backgroundColor: colors?.secondaryColor }}
          >
            <ScrollView
              pt="10"
              pb="6"
              mb="2"
              showsVerticalScrollIndicator={false}>
              <Text
                color="black"
                fontSize="xl"
                fontFamily="Inter"
                textAlign="justify"
                lineHeight="lg">
                {data?.description}
              </Text>
              <Box h="190" />
            </ScrollView>
            {
              !isLoading && data?.initial && (
                <Box position="absolute" bottom="0" left="0" right="0" pb="6">
                  {ptId && (
                    <Button
                      position="absolute"
                      zIndex={100}
                      onPress={() => setPtId(undefined)}
                      variant="unstyled"
                      right={8}
                      top={-60}>
                      <Icon
                        as={Icons}
                        name="arrow-left"
                        size={45}
                        color={colors?.textColorButton || 'white'}
                        bg={colors?.tertiaryColor || undefined}
                        borderRadius={30}
                      />
                    </Button>
                  )}
                  <ScrollView showsVerticalScrollIndicator={false} h="96">
                    <Stack space="6" py="8" alignItems="center">
                      {(passwordTypes.isFetching || passwordTypes.isLoading) && <Loading />}
                      {keyTypes.map(item => (
                        <QueueKeyModal
                          key={item.id}
                          titleQueue={String(data?.name)}
                          fullBack={() => onBack(true)}
                          setSubCategory={setPtId}
                          item={item}
                          queueTypeId={String(data?.id)}
                        />
                      ))}
                    </Stack>
                  </ScrollView>
                </Box>
              )
            }
            {
              data?.queueTypes && data.queueTypes.length > 0 && (
                <Box position="absolute" bottom="0" left="0" right="0" pb="6">
                  <ScrollView showsVerticalScrollIndicator={false} h="96">
                    <Stack space="6" py="8" alignItems="center">
                      {data.queueTypes.map(item => (
                        <QueueCard
                          id={item.id}
                          title={item.name}
                          fileUrl={item.fileUrl}
                          onPressItem={onPressItem}
                        />
                      ))}
                    </Stack>
                  </ScrollView>
                </Box>
              )
            }
          </Flex >
        }
      />
    </Layout >
  );
}
