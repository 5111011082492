import { Flex, Image, Text, Center } from 'native-base';
import { Pressable } from 'react-native';

import { useAllInfos } from '@hooks/configs/useAllInfo';

interface IProps {
  title: string;
  fileUrl?: string;
  type?: string;
  cardUrl?: string;
  w?: number;
  h?: number;
  rounded?: number;
  onPressItem(): void;
}

export function Card2({
  title,
  fileUrl,
  onPressItem,
  w,
  h,
  rounded,
  cardUrl,
  type
}: IProps) {
  const { colors, styles } = useAllInfos();

  return (
    <Pressable
      onPress={onPressItem}
      style={({ pressed }) => ({ opacity: pressed ? 0.7 : 1 })}>
      <Flex
        h={h || 150}
        w={w || 300}
        mx="2"
        bg={colors?.tertiaryColor || 'darkBlue.800'}
        borderRadius={rounded ?? '2xl'}
      >
        {fileUrl && (type !== 'Video' || !!cardUrl) && (
          <Image
            source={{ uri: cardUrl || fileUrl }}
            alt={title}
            style={{ height: '100%', borderRadius: rounded ?? 15 }}
          />
        )}
        <Center position='absolute' bottom="0" w="100%" top='0'>
          <Text
            color={colors?.textColorButton || 'white'}
            px="4"
            fontWeight="bold"
            fontSize={styles?.appFontSize || "xl"}
            lineHeight="md"
            textAlign="center">
            {title}
          </Text>
        </Center>
      </Flex>
    </Pressable >
  );
}
