import { FormControl, Heading, Stack } from 'native-base';
import React, { useMemo, useState } from 'react';
import DropDownPicker from 'react-native-dropdown-picker';

import { useNPSStore } from '@stores/useNPSStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

interface IProps {
  id: string;
  title: string;
  options: string[];
}

export function NPSMultipleSelect({ id, title, options }: IProps) {
  const { items, onChangeTexts } = useNPSStore();

  const [open, setOpen] = useState(false);
  const [itemsList, setItems] = useState(options.map(i => ({ value: i })));

  const { colors } = useAllInfos();

  function onChange(value: any) {
    onChangeTexts(id, value);
  }

  const item = useMemo(() => {
    return items.find(i => i.npsModelId === id);
  }, [items.length, id]);

  const [values, setValues] = useState(item?.texts || []);

  return (
    <FormControl>
      <Stack mx="4" h={open ? '64' : undefined}>
        <FormControl.Label
          _text={{ color: colors?.textColorSimple || undefined }}>
          <Heading color="gray.300">{title}</Heading>
        </FormControl.Label>
        <DropDownPicker
          key={id}
          items={itemsList}
          schema={{ label: 'value' }}
          open={open}
          setOpen={setOpen}
          setValue={setValues}
          onChangeValue={onChange}
          setItems={setItems}
          value={values}
          multiple
          min={0}
          mode="BADGE"
          placeholder=""
          listMode="SCROLLVIEW"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderBottomColor: '#505050',
            borderBottomWidth: 2,
            borderRadius: 0,
            height: 50,
          }}
          dropDownContainerStyle={{
            height: 200,
            position: 'relative',
            top: 0,
            marginBottom: 10,
            backgroundColor: '#515151',
          }}
          badgeProps={{
            style: {
              backgroundColor: '#505050',
              paddingHorizontal: 20,
              borderRadius: 100,
              paddingVertical: 5,
            },
          }}
          badgeTextStyle={{ color: '#F3F3F3' }}
          badgeDotStyle={{ display: 'none' }}
          listParentLabelStyle={{ textAlign: 'center', fontWeight: 'bold' }}
          listItemContainerStyle={{
            borderTopColor: '#C4C4C4',
            borderTopWidth: 2,
            height: 60,
          }}
          textStyle={{ color: '#F7F7F7', fontSize: 20 }}
        />
      </Stack>
    </FormControl>
  );
}
