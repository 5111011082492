import { Center, Button, Modal, HStack, Text, Heading } from 'native-base';
import React, { useState } from 'react';
import { ActivityIndicator } from 'react-native';

import { useAllInfos } from '@hooks/configs/useAllInfo';
import { useCreateQueueKey } from '@hooks/queues/useCreateQueueKey';

import { time1Second } from '@utils/times';

import { IQueuePasswordType } from '@interfaces/IQueuePasswordType';

import { OnPressBox } from './OnPressBox';

interface IProps {
  titleQueue: string;
  queueTypeId: string;
  fullBack: () => void;
  item: IQueuePasswordType;
  setSubCategory(id: string): void;
}

export function QueueKeyModal({
  titleQueue,
  queueTypeId,
  fullBack,
  item,
  setSubCategory,
}: IProps) {
  const { colors, styles } = useAllInfos();

  const [showModal, setShowModal] = useState(false);

  const create = useCreateQueueKey();

  function onClose() {
    setShowModal(false);
  }

  async function onGenerationKey() {
    if (create.isSuccess) return;
    await create.mutateAsync({ queueTypeId, queuePasswordTypeId: item.id });
    setTimeout(() => fullBack(), time1Second * 5);
  }

  async function onHandleSelect() {
    if (item.initial) {
      setShowModal(true);
      onGenerationKey();
    } else setSubCategory(item.id);
  }

  return (
    <Center flex="1" w="80">
      <Button
        py={styles?.btPY || '8'}
        w="100%"
        colorScheme="success"
        borderRadius="2xl"
        _text={{
          fontWeight: 'bold',
          color: colors?.textColorButton || undefined,
          fontSize: styles?.btFontSize || '2xl',
        }}
        bg={colors?.tertiaryColor || 'success.500'}
        onPress={onHandleSelect}>
        {item.name}
      </Button>
      <Modal isOpen={showModal} size="lg">
        <Modal.Content maxWidth="500">
          <Modal.Header _text={{ fontWeight: 'bold', fontSize: '2xl' }}>
            Gerar Senha
          </Modal.Header>
          <Modal.Body py="5">
            <Center>
              {create.isLoading ? (
                <OnPressBox>
                  <ActivityIndicator size="large" color="#FF3879" />
                </OnPressBox>
              ) : create.isSuccess ? (
                <OnPressBox bgBR="success.500">
                  <Text fontWeight="bold" color="blueGray.300">
                    {titleQueue}
                  </Text>
                  <Heading color="blueGray.100" mt="3">
                    Senha: {create.data.key}
                  </Heading>
                  <Text
                    mt="5"
                    color="blueGray.300">
                    {item.name}
                  </Text>
                </OnPressBox>
              ) : (
                <OnPressBox onPress={onGenerationKey} bgBR="red.800">
                  <Text fontWeight="medium">Ocorreu um erro</Text>
                  <Text color="blueGray.300">
                    Pressione aqui para tente novamente
                  </Text>
                  <Heading fontWeight="small" my="3">
                    {titleQueue}
                  </Heading>
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text fontWeight="medium">Tipo: </Text>
                    <Text
                      color="blueGray.300"
                      _light={{ color: 'blueGray.700' }}>
                      {item.name}
                    </Text>
                  </HStack>
                </OnPressBox>
              )}
            </Center>
          </Modal.Body>
          <Modal.Footer>
            <HStack w="100%" space="4" justifyContent="flex-end">
              {create.isError && (
                <Button minW="32" px="4" onPress={onClose} colorScheme="error">
                  Cancelar
                </Button>
              )}
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>
  );
}
