import { Box, Button, Heading, HStack } from 'native-base';
import React from 'react';

import { useNPSStore } from '@stores/useNPSStore';

interface IProps {
  id: string;
  title: string;
}

type INumber = {
  int: number;
  onPress: (i: number) => void;
  bg: string;
  value: number;
};

function ButtonNumber({ value, onPress, bg, int }: INumber) {
  return (
    <Button
      onPress={() => onPress(int)}
      bg={bg}
      flex="1"
      h="12"
      isDisabled={value === int}>
      <Heading color="white" size="md">
        {int}
      </Heading>
    </Button>
  );
}

export function To1From5({ id, title }: IProps) {
  const { items, onChangeValue } = useNPSStore();

  function onChange(i: number) {
    onChangeValue(id, i);
  }

  const item = items.find(i => i.npsModelId === id)?.value || 0;

  return (
    <Box>
      <Heading color="gray.200">{title}</Heading>
      <HStack space="2" mt="4">
        <ButtonNumber onPress={onChange} int={1} bg="red.600" value={item} />
        <ButtonNumber onPress={onChange} int={2} bg="red.400" value={item} />
        <ButtonNumber onPress={onChange} int={3} bg="yellow.500" value={item} />
        <ButtonNumber onPress={onChange} int={4} bg="green.400" value={item} />
        <ButtonNumber onPress={onChange} int={5} bg="green.600" value={item} />
      </HStack>
    </Box>
  );
}
