import { create } from 'zustand';

type Item = {
  npsModelId: string;
  value?: number;
  text?: string;
  texts?: string[];
};

type IProps = {
  items: Item[];
  onChangeValue(id: string, i: number): void;
  onChangeText(id: string, text: string): void;
  onChangeTexts(id: string, texts: string[]): void;
  onDestroy(): void;
};

export const useNPSStore = create<IProps>((set, _, { setState }) => ({
  items: [],
  onChangeValue(npsModelId, i) {
    set(s => {
      const { items } = s;
      const is = items.findIndex(item => item.npsModelId === npsModelId);

      if (is === -1) {
        items.push({ npsModelId, value: i });
      } else {
        items[is].value = i;
      }

      return { items };
    });
  },
  onChangeText(npsModelId, text) {
    set(s => {
      const { items } = s;
      const is = items.findIndex(item => item.npsModelId === npsModelId);

      if (is === -1) {
        items.push({ npsModelId, text });
      } else {
        items[is].text = text;
      }

      return { items };
    });
  },
  onChangeTexts(npsModelId, texts) {
    set(s => {
      const { items } = s;
      const is = items.findIndex(item => item.npsModelId === npsModelId);

      if (is === -1) {
        items.push({ npsModelId, texts });
      } else {
        items[is].texts = texts;
      }

      return { items };
    });
  },
  onDestroy() {
    setState(
      ({ onChangeValue, onDestroy, onChangeText, onChangeTexts }) => ({
        onChangeValue,
        onChangeText,
        onChangeTexts,
        onDestroy,
        items: [],
      }),
      true,
    );
  },
}));
