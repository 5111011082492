import {
  Text,
  Heading,
  Box,
  FlatList,
  Stack,
  Button,
  HStack,
} from 'native-base';
import React from 'react';
import { KeyboardAvoidingView } from 'react-native';

import { RouteAppProps } from '@routes/types';

import { useResponseStore } from '@stores/useResponseStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';
import { useCreateAnswerForm } from '@hooks/items/useCreateAnswerForm';
import { useItem } from '@hooks/items/useItem';

import { showToast } from '@utils/showToast';

import { InputForm } from '@components/InputForm';
import { Layout } from '@components/Layout';
import { Loading } from '@components/Loading';

import 'react-simple-keyboard/build/css/index.css';
import { time1Second } from '@utils/times';

export function Form({ navigation, route }: RouteAppProps<'Form'>) {
  const { id } = route.params;

  const { colors, styles } = useAllInfos();
  const { data, isLoading } = useItem(id);

  const { onCheck, onDestroy, items } = useResponseStore();

  function onBack() {
    onDestroy();
    navigation.goBack();
  }

  const useSend = useCreateAnswerForm();

  async function onChangeResponse() {
    onCheck();

    let isError = false;

    const newData = data?.inputs?.map(item => {
      const isValue = items.find(i => i.inputId === item.id);

      if (item.required && !isValue?.value) isError = true;
      if (item.minLength && isValue?.value) {
        if (item.type === 'Number') {
          if (Number(isValue?.value) < item.minLength) {
            isError = true;
          }
        } else {
          if (isValue?.value?.length < item.minLength) {
            isError = true;
          }
        }
      }
      if (item.maxLength && isValue?.value) {
        if (item.type === 'Number') {
          if (Number(isValue?.value) > item.maxLength) {
            isError = true;
          }
        } else {
          if (isValue?.value?.length > item.maxLength) {
            isError = true;
          }
        }
      }

      return { inputId: item.id, value: isValue?.value || undefined };
    });

    if (isError || !newData?.length) {
      showToast({
        message: 'Preencha todos os campos corretamente',
        type: 'info',
        title: 'Dados inválidos',
      });
    } else {
      await useSend.mutateAsync({ data: newData, id });
      onBack();
    }
  }

  return (
    <Layout
      onAction={isActive => !isActive && onBack()}
      timeForInactivity={
        data?.inactiveActiveMin && data?.inactiveActiveMin * time1Second
      }>
      <KeyboardAvoidingView style={{ flex: 1 }} behavior="height">
        <Stack flex="1" space="5" paddingX="6">
          <Heading
            fontSize={styles?.titleFontSize || "3xl"}
            textAlign="center"
            fontWeight="bold"
            w="100%"
            color={colors?.textColorTitle || 'gray.100'}>
            {data?.title}
          </Heading>
          <Text
            fontSize={styles?.appSecFontSize || "md"}
            lineHeight={22}
            color={colors?.textColorSimple || 'gray.300'}>
            {data?.description}
          </Text>
          <Box w="100%">
            {isLoading ? (
              <Loading />
            ) : (
              <FlatList
                data={data?.inputs || []}
                keyExtractor={item => item.id}
                showsVerticalScrollIndicator={false}
                h='75vh'
                renderItem={({ item }) => (
                  <InputForm
                    id={item.id}
                    name={item.name}
                    options={item.options}
                    order={item.order}
                    required={item.required}
                    type={item.type}
                    maxLength={item.maxLength}
                    minLength={item.minLength}
                    key={item.id}
                  />
                )}
                ItemSeparatorComponent={() => <Box h="5" />}
                ListFooterComponent={
                  <HStack
                    px="4"
                    space="6"
                    mt="10"
                    pb="56"
                    justifyContent="flex-end">
                    <Button
                      key="back"
                      onPress={onBack}
                      _text={{
                        fontWeight: 'normal',
                        color: colors?.textColorSimple || undefined,
                      }}
                      variant="Unstyled"
                      h="50px">
                      Voltar
                    </Button>
                    <Button
                      onPress={onChangeResponse}
                      colorScheme="success"
                      key="send"
                      isLoading={useSend.isLoading}
                      h="50px"
                      w="48"
                      bg={colors?.tertiaryColor || undefined}
                      _text={{
                        fontWeight: 'bold',
                        color: colors?.textColorButton || undefined,
                      }}>
                      Enviar
                    </Button>
                  </HStack>
                }
              />
            )}
          </Box>
        </Stack>
      </KeyboardAvoidingView>
    </Layout>
  );
}
