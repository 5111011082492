import { Box, Center, Heading, HStack, Pressable } from 'native-base';
import React from 'react';
import Icons from '@expo/vector-icons/FontAwesome5';


import { useNPSStore } from '@stores/useNPSStore';

interface IProps {
  id: string;
  title: string;
}

type INumber = {
  int: number;
  onPress: (i: number) => void;
  value: number;
  icon: string;
  bg: string;
};

function EmojiPress({ value, onPress, int, icon, bg }: INumber) {
  return (
    <Pressable
      onPress={() => onPress(int)}
      bg={bg}
      flex="1"
      py="2"
      isDisabled={value === int}
      opacity={value === int ? 0.7 : 1}>
      <Center>
        <Icons name={icon} size={60} solid={value === int} color="white" />
      </Center>
    </Pressable>
  );
}

export function Emoji({ id, title }: IProps) {
  const { items, onChangeValue } = useNPSStore();

  function onChange(i: number) {
    onChangeValue(id, i);
  }

  const item = items.find(i => i.npsModelId === id)?.value || 0;

  return (
    <Box>
      <Heading color="gray.200">{title}</Heading>
      <HStack mt="4">
        <EmojiPress
          onPress={onChange}
          int={1}
          value={item}
          icon="angry"
          bg="red.600"
        />
        <EmojiPress
          onPress={onChange}
          int={2}
          value={item}
          icon="frown"
          bg="red.400"
        />
        <EmojiPress
          onPress={onChange}
          int={3}
          value={item}
          icon="meh"
          bg="yellow.500"
        />
        <EmojiPress
          onPress={onChange}
          int={4}
          value={item}
          icon="smile"
          bg="green.400"
        />
        <EmojiPress
          onPress={onChange}
          int={5}
          value={item}
          icon="smile-beam"
          bg="green.600"
        />
      </HStack>
    </Box>
  );
}
