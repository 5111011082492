import { Image } from './Image';
import { Video } from './Video';

interface IProps {
  type: string;
  items: string[];
  isActive: boolean;
}

export function ShowInactivity({ type, items, isActive }: IProps) {
  if (!isActive) return null;
  if (!items.length) return null;

  if (type === 'list-video') return Video({ videos: items });
  if (type === 'image') return Image({ images: items });

  return null;
}
