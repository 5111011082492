import { FormControl, Stack, Heading } from 'native-base';
import React, { useState } from 'react';
import DropDownPicker from 'react-native-dropdown-picker';

import { useNPSStore } from '@stores/useNPSStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

interface IProps {
  id: string;
  title: string;
  options: string[];
}

export function NPSSelect({ id, title, options }: IProps) {
  const { items, onChangeText } = useNPSStore();

  const { colors } = useAllInfos();

  const item = items.find(i => i.npsModelId === id)?.text as string;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(item);
  const [itemsList, setItems] = useState(options?.map(i => ({ value: i })));

  function onChange(i: string | null) {
    onChangeText(id, i || '');
  }

  return (
    <FormControl>
      <Stack mx="4">
        <FormControl.Label
          _text={{ color: colors?.textColorSimple || undefined }}>
          <Heading color="gray.300">{title}</Heading>
        </FormControl.Label>
        <DropDownPicker
          key={id}
          open={open}
          setOpen={setOpen}
          schema={{ label: 'value' }}
          value={value}
          onChangeValue={onChange}
          setValue={setValue}
          items={itemsList}
          setItems={setItems}
          placeholder=""
          listMode="SCROLLVIEW"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderBottomColor: '#505050',
            borderBottomWidth: 2,
            borderRadius: 0,
            height: 50,
          }}
          dropDownContainerStyle={{
            height: 200,
            position: 'relative',
            top: 0,
            marginBottom: 20,
            backgroundColor: '#515151',
          }}
          listItemContainerStyle={{
            borderTopColor: '#C4C4C4',
            borderTopWidth: 2,
            height: 60,
          }}
          listParentLabelStyle={{ textAlign: 'center', fontWeight: 'bold' }}
          textStyle={{ color: '#F3F3F3', fontSize: 20 }}
        />
      </Stack>
    </FormControl>
  );
}
