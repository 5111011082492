import Toast, { ToastShowParams } from 'react-native-toast-message';

interface IToast extends ToastShowParams {
  title: string;
  message: string;
  type?: 'success' | 'error' | 'info';
}

export function showToast({ message, title, type = 'success' }: IToast) {
  Toast.show({ type, text1: title, text2: message });
}
