import { Center } from 'native-base';
import { ActivityIndicator } from 'react-native';

export function Loading() {
  return (
    <Center flex={1}>
      <ActivityIndicator size="large" color="#FF3879" />
    </Center>
  );
}
