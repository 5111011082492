import { Heading, Box, FlatList, Button, HStack, Divider } from 'native-base';
import React from 'react';

import { RouteAppProps } from '@routes/types';

import { useNPSStore } from '@stores/useNPSStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';
import { useCreateNpsAnswer } from '@hooks/nps/useCreateNpsAnswer';
import { useNpsItem } from '@hooks/nps/useNpsItem';

import { Layout } from '@components/Layout';
import { Loading } from '@components/Loading';
import { NpsModel } from '@components/NpsModel';

import 'react-simple-keyboard/build/css/index.css';

export function Nps({ navigation, route }: RouteAppProps<'Nps'>) {
  const { id } = route.params;

  const { colors, styles } = useAllInfos();
  const { data, isLoading } = useNpsItem(id);

  const { items, onDestroy } = useNPSStore();

  function onBack() {
    onDestroy();
    navigation.goBack();
  }

  const useSend = useCreateNpsAnswer();

  async function onChangeResponse() {
    await useSend.mutateAsync({ data: items, npsId: id });
    onBack();
  }

  return (
    <Layout onAction={isActive => !isActive && onBack()}>
      <Box mx="4">
        <Heading
          textAlign="center"
          color={colors?.textColorSimple || 'white'}
          fontSize={styles?.titleFontSize || '3xl'}
        >
          {data?.title}
        </Heading>
        <Divider bg="gray.700" mb="12" />
        {isLoading ? (
          <Loading />
        ) : (
          <FlatList
            data={data?.models || []}
            keyExtractor={item => item.id}
            showsVerticalScrollIndicator={false}
            h='75vh'
            renderItem={({ item }) => (
              <NpsModel
                id={item.id}
                title={item.title}
                type={item.type}
                options={item.options}
                key={item.id}
              />
            )}
            ItemSeparatorComponent={() => <Box h="5" />}
            ListFooterComponent={
              <HStack
                px="4"
                space="6"
                mt="10"
                pb="56"
                justifyContent="flex-end">
                <Button
                  key="back"
                  onPress={onBack}
                  h="50px"
                  w="32"
                  borderRadius="lg"
                  _text={{
                    fontWeight: 'bold',
                    color: colors?.textColorButton || undefined,
                  }}
                  _dark={{ bg: colors?.tertiaryColor || 'gray.800' }}
                  _light={{ bg: colors?.tertiaryColor || 'darkBlue.800' }}>
                  Voltar
                </Button>
                <Button
                  key="send"
                  onPress={onChangeResponse}
                  colorScheme="success"
                  isLoading={useSend.isLoading}
                  h="50px"
                  w="48"
                  bg={colors?.tertiaryColor || undefined}
                  _text={{
                    fontWeight: 'bold',
                    color: colors?.textColorButton || undefined,
                  }}
                  isDisabled={items.length === 0}>
                  Enviar
                </Button>
              </HStack>
            }
          />
        )}
      </Box>
    </Layout>
  );
}
