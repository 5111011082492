import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IError, ISuccess } from '@interfaces/IResponse';

interface IItem {
  url: string;
}

async function list(): Promise<string[]> {
  const { data } = await api.get<ISuccess<IItem[]>>(
    `/${authStore.getState().schema}/item/all/files?active=true`,
  );

  return data.payload.map(i => i.url);
}

export function useAllFiles() {
  return useQuery<string[], IError>(['Items', 'files'], () => list(), {
    staleTime: 1000 * 60 * 10,
  });
}
