import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { SelectCompany } from '@screens/auth/SelectCompany';
import { SingIn } from '@screens/auth/SingIn/SingIn';

import { AuthParamList } from './types';

const AuthStack = createNativeStackNavigator<AuthParamList>();

export function AuthRoutes() {
  return (
    <AuthStack.Navigator
      initialRouteName="SelectCompany"
      screenOptions={{ headerShown: false }}>
      <AuthStack.Screen name="SelectCompany" component={SelectCompany} />
      <AuthStack.Screen name="SignIn" component={SingIn} />
    </AuthStack.Navigator>
  );
}
