import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IError, ISuccess } from '@interfaces/IResponse';
import { IVideo } from '@interfaces/IVideo';
import { IPagination } from '@interfaces/pagination';

interface IRequest {
  limit?: number;
  page?: number;
  name?: string;
}

async function list(params: IRequest): Promise<IVideo[]> {
  const { data } = await api.get<ISuccess<IPagination<IVideo>>>(
    `/${authStore.getState().schema}/videos`,
    { params: { ...params, disabled: false, limit: 100 } },
  );

  // return data.payload.data;
  return data?.payload?.data?.map(item => ({ ...item, type: 'Video' }));
}

export function useVideos(params: IRequest = {}) {
  return useQuery<IVideo[], IError>(['Videos', params], () => list(params), {
    staleTime: 1000 * 60 * 10,
  });
}
