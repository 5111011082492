import { Checkbox, HStack, Text } from 'native-base';
import React, { useCallback, useMemo } from 'react';

import { useResponseStore } from '@stores/useResponseStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

interface IProps {
  id: string;
  name: string;
}

export function InputCheckbox({ id, name }: IProps) {
  const { items, onChangeValue } = useResponseStore();
  const { colors } = useAllInfos();

  const onChange = useCallback(
    (value: boolean) => {
      onChangeValue(id, value);
    },
    [onChangeValue],
  );

  const item = useMemo(() => {
    return items.find(i => i.inputId === id);
  }, [items.length, id]);

  return (
    <HStack w="100%" ml="7" space="4">
      <Checkbox
        onChange={onChange}
        value="a"
        defaultIsChecked={item?.value}
        accessibilityLabel="This is a dummy checkbox"
      />
      <Text color={colors?.textColorSimple || 'gray.300'}>{name}</Text>
    </HStack>
  );
}
