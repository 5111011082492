import asyncStorage from '@react-native-async-storage/async-storage';
import { useMutation } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { showToast } from '@utils/showToast';

import { IError } from '@interfaces/IResponse';

type IDataResponse = {
  inputId: string;
  value?: string | number | boolean | string[];
}[];

interface IProps {
  data: IDataResponse;
  id: string;
}

async function callApi({ data, id }: IProps): Promise<void> {
  await api.post(`/${authStore.getState().schema}/item/${id}/answer`, { data });
}

function onSuccess() {
  showToast({
    title: 'Questionário enviado com sucesso!',
    message: 'Obrigado por participar!',
  });
}

function onError(error: IError) {
  console.log(error);

  showToast({
    title: 'Erro ao enviar o questionário',
    message: 'Tente novamente mais tarde',
    type: 'error',
  });
}

export function useCreateAnswerForm() {
  return useMutation(callApi, {
    onSuccess,
    onError,
  });
}
