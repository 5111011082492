import { Box, Button, Heading, Input, Modal, TextArea } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';

import Keyboard from 'react-simple-keyboard';

type IProps = {
  onchangeText: (i: string) => void;
  value?: string;
  name: string;
}

export function KeyboardNumber({ onchangeText, value, name }: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [number, setNumber] = useState('');
  const [layoutName, setLayoutName] = useState('default');
  const keyboard = useRef<any>();


  function onClose() {
    setIsOpen(false);
  }

  function onKeyPress(i: string) {
    if (i === "{shift}" || i === "{lock}") setLayoutName(layoutName === "default" ? "shift" : "default");
  }

  function onChange(i: string) {
    setNumber(i);
  }

  function onChangeInput(event: any) {
    const input = event.target.value;
    setNumber(input);
    keyboard?.current?.setInput(input);
  };

  function onSave() {
    onchangeText(number);
    onClose();
  }

  useEffect(() => {
    if (isOpen && value) setNumber(value);
  }, [isOpen]);

  return (
    <Box>
      <Pressable onPress={() => setIsOpen(true)}>
        <Input value={value} editable={false} color={"gray.200"} />
      </Pressable>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <Modal.Content w="90%">
          <Modal.CloseButton />
          <Modal.Header>
            <Heading>{name}</Heading>
          </Modal.Header>
          <Modal.Body>
            <Input value={number} onChange={onChangeInput} bg='gray.200' />
            {isOpen && <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              display={{ '{bksp}': '←' }}
              layout={{ default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'] }}
              layoutName={layoutName}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={onClose} colorScheme="danger" px="12">
              Fechar
            </Button>
            <Button onPress={onSave} colorScheme="success" px="12" ml='4'>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
}
