import { Flex } from 'native-base';
import React, { cloneElement, ReactElement } from 'react';

import { isLandscape } from '@utils/dimensions';

interface IProps {
  itemFist: ReactElement;
  itemSecond: ReactElement;
}

export function ScreeLayout({ itemFist, itemSecond }: IProps) {
  return (
    <Flex
      ml={isLandscape('10%')}
      mr={isLandscape('21%')}
      pl={isLandscape('80')}
      px={isLandscape(undefined, '12')}
      alignItems={isLandscape(undefined, 'center')}
      h={isLandscape('80%', '90%')}>
      {cloneElement(itemFist, {
        w: '80',
        space: '1.5',
        position: isLandscape('absolute'),
        left: isLandscape('0'),
        zIndex: 1,
      })}
      {cloneElement(itemSecond, {
        background: 'gray.800',
        flex: '1',
        w: isLandscape('104%', '100%'),
        ml: isLandscape('-3%'),
        pl: isLandscape('5%', '2%'),
        pr: '2%',
        mt: isLandscape(undefined, '6'),
      })}
    </Flex>
  );
}
