import { IItem } from '@interfaces/IItem';
import { IVideo } from '@interfaces/IVideo';

export const types = ['Info', 'Form', 'Video'];
export const topFormat = ['Card Top', 'Banner'];
export const bottomFormat = ['Card Bottom', 'Video'];

export function filter(
  items: (IItem | IVideo)[],
  type: 'List' | 'image' | 'list-video' | 'queue-only',
  imgs: string[] | undefined
): string[] {
  if (type === 'image') {
    const newItems = items
      .filter((i) => i.type === 'Info' && i.fileUrl)
      .map((i) => i.fileUrl as string);

    return imgs ? [...newItems, ...imgs] : newItems;
  }
  if (type === 'list-video') {
    return items
      .filter((i) => i?.type === 'Video' && i.fileUrl)
      .map((i) => i.fileUrl) as string[];
  }

  return [];
}

export type ItemPress = {
  type?: 'Form' | 'Link Web' | 'Info' | 'Video';
  link?: string;
  linkIncognito?: boolean;
};

export type ILinkWeb = {
  link: string;
  linkIncognito?: boolean;
};
