import { Center } from 'native-base';
import React, { ReactNode } from 'react';
import { Pressable } from 'react-native';

interface IBoxProp {
  onPress?: () => void;
  children: ReactNode;
  bgBR?: string;
}

export function OnPressBox({ onPress, children, bgBR }: IBoxProp) {
  return (
    <Pressable onPress={onPress}>
      <Center
        h="40"
        minW="48"
        px="3"
        bg="gray.800"
        borderColor={bgBR || 'white'}
        borderWidth="2"
        borderRadius="2xl">
        {children}
      </Center>
    </Pressable>
  );
}
