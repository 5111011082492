import AsyncStorage from '@react-native-async-storage/async-storage';
import { create, useStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

import { queryClient } from '@services/queryClient';


type ILayout = {
  typeLayout: 'type1' | 'type2';
  columns: number;
  width?: number;
  height?: number;
  rounded?: number;
};

type ILayoutStore = ILayout & {
  onLayout(items: ILayout): void;
}
export const LayoutStore = createStore(
  persist<ILayoutStore>(
    set => ({
      typeLayout: 'type1',
      columns: 3,
      onLayout(items) {
        set(items);
      },
    }),
    {
      name: 'layout-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export function useLayoutStore() {
  return useStore(LayoutStore);
}
