export function getCurrentTime() {
  let today = new Date();
  let hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
  let minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();

  return hours + ':' + minutes;
}

export function getCurrentTime2() {
  const today = new Date();
  const hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
  const minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
  const seconds = (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();

  return `${hours}:${minutes}:${seconds}`;
}

export function getCurrentDate() {
  const today = new Date();
  const day = (today.getDate() < 10 ? '0' : '') + today.getDate();
  const month = (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1);
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
}
