import { FormControl, Heading, Stack, TextArea } from 'native-base';
import React from 'react';

import { useNPSStore } from '@stores/useNPSStore';
import useDebounce from '@utils/useDebounce';
import { KeyboardArea } from '@components/Keyboard/KeyboardArea';

interface IProps {
  id: string;
  title: string;
}

export function NpsText({ id, title }: IProps) {
  const { items, onChangeText } = useNPSStore();
  const valueDebounce = useDebounce(onChangeText);
  const value = items.find(i => i.npsModelId === id)?.text;


  function onChange(i: string) {
    valueDebounce(id, i);
  }

  return (
    <FormControl>
      <Stack>
        <FormControl.Label >
          <Heading color="gray.200">{title}</Heading>
        </FormControl.Label>
        <KeyboardArea
          onchangeText={onChange}
          value={value}
          name={title}
        />
      </Stack>
    </FormControl>
  );
}
