import { Box, Button, Heading, HStack } from 'native-base';
import React from 'react';

import { useNPSStore } from '@stores/useNPSStore';

interface IProps {
  id: string;
  title: string;
}

type INumber = {
  int: number;
  onPress: (i: number) => void;
  bg: string;
  value: number;
};

function ButtonNumber({ value, onPress, bg, int }: INumber) {
  return (
    <Button
      onPress={() => onPress(int)}
      bg={bg}
      flex="1"
      h="12"
      px="0"
      mx="0"
      isDisabled={value === int}>
      <Heading color="white" fontSize="lg" textAlign="center" m="0">
        {int}
      </Heading>
    </Button>
  );
}

export function To1From10({ id, title }: IProps) {
  const { items, onChangeValue } = useNPSStore();

  function onChange(i: number) {
    onChangeValue(id, i);
  }

  const item = items.find(i => i.npsModelId === id)?.value || 0;

  return (
    <Box>
      <Heading color="gray.200">{title}</Heading>
      <HStack space="1" mt="4">
        <ButtonNumber onPress={onChange} int={1} bg="red.700" value={item} />
        <ButtonNumber onPress={onChange} int={2} bg="red.500" value={item} />
        <ButtonNumber onPress={onChange} int={3} bg="red.400" value={item} />
        <ButtonNumber onPress={onChange} int={4} bg="yellow.300" value={item} />
        <ButtonNumber onPress={onChange} int={5} bg="yellow.500" value={item} />
        <ButtonNumber onPress={onChange} int={6} bg="yellow.600" value={item} />
        <ButtonNumber onPress={onChange} int={7} bg="yellow.700" value={item} />
        <ButtonNumber onPress={onChange} int={8} bg="green.400" value={item} />
        <ButtonNumber onPress={onChange} int={9} bg="green.600" value={item} />
        <ButtonNumber onPress={onChange} int={10} bg="green.800" value={item} />
      </HStack>
    </Box>
  );
}
