import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  Select,
  Stack,
  Text,
} from 'native-base';
import React, { useCallback, useEffect, useRef, useState, } from 'react';

import { usePrintStore } from '@stores/usePrintStore';
import { showToast } from '@utils/showToast';
import { useLayoutStore } from '@stores/useLayoutStore';

export function SetLayout() {
  const { columns, onLayout, typeLayout, height, rounded, width } = useLayoutStore();

  const typeRef = useRef<any>(null);
  const heightRef = useRef<any>(null);
  const widthRef = useRef<any>(null);
  const roundedRef = useRef<any>(null);
  const columnsRef = useRef<any>(null);

  const [type, setTypeLayout] = useState<string>();

  const [selected, setSelected] = useState<'h' | 'w' | 'r'>('h');

  useEffect(() => setTypeLayout(typeLayout), [typeLayout]);

  const handleSelect = useCallback(
    () => {
      const typeLayout = typeRef.current?.value;
      const height = parseFloat(heightRef.current?.value);
      const width = parseFloat(widthRef.current?.value);
      const rounded = parseFloat(roundedRef.current?.value);
      const columns = parseInt(columnsRef.current?.value);

      onLayout({
        typeLayout,
        height: isNaN(height) ? undefined : height,
        width: isNaN(width) ? undefined : width,
        rounded: isNaN(rounded) ? undefined : rounded,
        columns: isNaN(columns) ? 3 : columns,
      })

      window.location.reload();
    },
    [],
  );

  function changePin(i: string) {


    const current = selected === 'h' ? heightRef.current : selected === 'w' ? widthRef.current : roundedRef.current;

    if (i === 'backspace') {
      current?.setNativeProps({ text: current?.value?.slice(0, -1) });
    } else {
      current?.setNativeProps({ text: `${current?.value}${i}` });
    }
  }

  return (
    <Box>
      <Text
        textAlign="justify"
        color="black"
        fontSize="xl"
        lineHeight="40">
        Configurações de impressão
      </Text>
      <Stack mt='8' space='4' >
        <FormControl>
          <FormControl.Label>
            Tipo de layout
          </FormControl.Label>
          <Select defaultValue={typeLayout} onValueChange={setTypeLayout} ref={typeRef} bg='gray.500' rounded='md'>
            <Select.Item value="type1" label="Tipo 1" />
            <Select.Item value="type2" label="Tipo 2" />
          </Select>
        </FormControl>
        {type === 'type2' && (
          <>
            <FormControl>
              <FormControl.Label>
                Colunas
              </FormControl.Label>
              <Select defaultValue={columns.toString()} ref={columnsRef} bg='gray.500' rounded='md'>
                {
                  Array.from({ length: 10 }, (_, i) => i + 1).map((item) => (
                    <Select.Item key={item} value={item.toString()} label={item.toString()} />
                  ))
                }
              </Select>
            </FormControl>
            <Flex direction='row'>

              <Box w='60%' pr='10%'>
                <FormControl >
                  <FormControl.Label>
                    Altura
                  </FormControl.Label>
                  <Input
                    autoComplete='off'
                    returnKeyType="done"
                    ref={heightRef}
                    defaultValue={height?.toString()}
                    disableFullscreenUI
                    bg='gray.500'
                    onFocus={() => setSelected('h')}
                    style={{
                      borderWidth: selected === 'h' ? 2 : 0,
                      borderColor: selected === 'h' ? 'green' : undefined,
                    }}
                    rounded='md'
                  />
                </FormControl>
                <FormControl >
                  <FormControl.Label>
                    Largura
                  </FormControl.Label>
                  <Input
                    autoComplete='off'
                    returnKeyType="done"
                    ref={widthRef}
                    defaultValue={width?.toString()}
                    disableFullscreenUI
                    bg='gray.500'
                    rounded='md'
                    onFocus={() => setSelected('w')}
                    style={{
                      borderWidth: selected === 'w' ? 2 : 0,
                      borderColor: selected === 'w' ? 'green' : undefined,
                    }}
                  />
                </FormControl>
                <FormControl >
                  <FormControl.Label>
                    Arredondamento
                  </FormControl.Label>
                  <Input
                    autoComplete='off'
                    returnKeyType="done"
                    ref={roundedRef}
                    defaultValue={rounded?.toString()}
                    disableFullscreenUI
                    bg='gray.500'
                    rounded='md'
                    onFocus={() => setSelected('r')}
                    style={{
                      borderWidth: selected === 'r' ? 2 : 0,
                      borderColor: selected === 'r' ? 'green' : undefined,
                    }}
                  />
                </FormControl>
              </Box>
              <Box flex='1'>
                <HStack space="3" bg='red.200 '>
                  <Button flex='1' onPress={() => changePin('1')}>1</Button>
                  <Button flex='1' onPress={() => changePin('2')}>2</Button>
                  <Button flex='1' onPress={() => changePin('3')}>3</Button>
                </HStack>
                <HStack space="3" mt="3">
                  <Button flex='1' onPress={() => changePin('4')}>4</Button>
                  <Button flex='1' onPress={() => changePin('5')}>5</Button>
                  <Button flex='1' onPress={() => changePin('6')}>6</Button>
                </HStack>
                <HStack space="3" mt="3">
                  <Button flex='1' onPress={() => changePin('7')}>7</Button>
                  <Button flex='1' onPress={() => changePin('8')}>8</Button>
                  <Button flex='1' onPress={() => changePin('9')}>9</Button>
                </HStack>
                <HStack space="3" mt="3">
                  <Button flex='1' onPress={() => changePin('0')}>0</Button>
                  <Button flex='2' onPress={() => changePin('backspace')} colorScheme="danger">
                    ⌦
                  </Button>
                </HStack>
              </Box>
            </Flex>
          </>
        )}

      </Stack>
      <HStack mt='8' justifyContent='end'>
        <Button onPress={handleSelect} colorScheme="success" px="12">
          Salvar
        </Button>
      </HStack>
    </Box>
  );
}
