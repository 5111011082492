import { useRef } from 'react';

export default function useDebounce(fn: (...arg: any) => void, delay = 500) {
  const timeoutRef = useRef<any>(null);

  function debouche(...arg: any) {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      fn(...arg);
    }, delay);
  }

  return debouche;
}
