import { Flex, Button, Image, Text } from 'native-base';
import { Pressable } from 'react-native';

import { useAllInfos } from '@hooks/configs/useAllInfo';

interface IProps {
  title: string;
  fileUrl?: string;
  cardUrl?: string;
  type?: string
  w: number | string;
  column: number;
  onPressItem(): void;
}

export function Card({
  title,
  fileUrl,
  onPressItem,
  type,
  w,
  column,
  cardUrl
}: IProps) {
  const { colors, styles } = useAllInfos();

  const isH = !fileUrl && column === 1;

  return (
    <Pressable
      onPress={onPressItem}
      style={({ pressed }) => ({ opacity: pressed ? 0.7 : 1 })}>
      <Flex
        h={isH ? undefined : '64'}
        w={typeof w === 'number' ? w - 16 : w}
        mx="2"
        borderRadius="2xl"
        _dark={{ bg: colors?.secondaryColor || ' rgba(255, 255, 255, 0.18)' }}
        _light={{ bg: colors?.secondaryColor || 'gray.200' }}>
        {fileUrl && (type !== 'Video' || !!cardUrl) && (
          <Image
            source={{ uri: cardUrl || fileUrl }}
            alt={title}
            style={{ height: '100%', borderRadius: 15 }}
          />
        )}
        <Text
          bg={colors?.tertiaryColor || 'darkBlue.800'}
          color={colors?.textColorButton || 'white'}
          fontSize={styles?.appFontSize || undefined}
          py={isH ? '5' : '3'}
          px="4"
          fontWeight="bold"
          position={isH ? undefined : 'absolute'}
          bottom="-2"
          w="100%"
          borderTopRadius={isH ? '2xl' : undefined}
          borderBottomRadius="2xl"
          lineHeight="md"
          textAlign="center"
          mt={isH ? '-1' : undefined}>
          {title}
        </Text>
      </Flex>
    </Pressable>
  );
}
