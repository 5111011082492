import { Center } from 'native-base';
import React, { useRef, useState } from 'react';

interface IProps {
  videos: string[];
}

let count = 1;

export function Video({ videos }: IProps) {
  const [uri, setUri] = useState(videos[0]);
  const videoRef = useRef<HTMLVideoElement>(null);

  function onEnd() {
    if (videos.length === 1) {
      videoRef.current?.play();
      return;
    }

    if (videos.length > count) {
      count += 1;
    } else {
      count = 1;
    }
    setUri(() => videos[count - 1]);
  }

  return (
    <Center
      w="100%"
      h="100%"
      position="absolute"
      paddingY="5"
      bg="black"
      opacity="0.99">
      {uri && (
        <video
          ref={videoRef}
          src={uri}
          autoPlay
          controls={false}
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
          }}
          onEnded={onEnd}
        />
      )}
    </Center>
  );
}
