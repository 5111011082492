
import axios, { AxiosError } from 'axios';
import { AxiosRequestHeaders } from 'axios';

import { authStore } from '@stores/useAuthStore';

import { AppError } from './errors/AppErro';
import { URLS } from '@utils/urls';

let isRefreshToken = false;
let failedRequestsQueue: any[] = [];

const api = axios.create({ baseURL: URLS.URL_REST });


api.interceptors.response.use(
  response => response,
  async (error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      if (error.response.data?.message === 'Token expired') {
        const originalConfig = error.config;
        if (!isRefreshToken) {
          isRefreshToken = true;

          const { refreshToken, onRefreshToken, onOutLogin } =
            authStore.getState();

          await api
            .post(`/auth/refresh-token?refresh=${refreshToken}`)
            .then(async ({ data }) => {
              onRefreshToken(data.payload.token);

              api.defaults.headers.common.Authorization = `Bearer ${data.payload.token}`;

              failedRequestsQueue.forEach(request =>
                request.onSuccess(data.payload.token),
              );
              failedRequestsQueue = [];
            })
            .catch(() => {
              failedRequestsQueue.forEach(request => request.onFailure());
              failedRequestsQueue = [];
              onOutLogin();
            })
            .finally(() => {
              isRefreshToken = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              if (!originalConfig) return;

              originalConfig.headers = {
                ...originalConfig.headers,
                Authorization: `Bearer ${token}`,
              } as AxiosRequestHeaders;

              resolve(api(originalConfig));
            },
            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }

      return Promise.reject(new AppError(error?.response?.data));
    }

    return Promise.reject(new AppError(error?.response?.data));
  },
);

api.interceptors.request.use(request => {
  if (!request.headers.Authorization) {
    const { token } = authStore?.getState();

    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      request.headers.Authorization = `Bearer ${token}`;
    }
  }

  return request;
});

export { api };
