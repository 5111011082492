import { Flex, Button, Stack, Text, ScrollView, Box } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';

import { RouteAppProps } from '@routes/types';

import { useAllInfos } from '@hooks/configs/useAllInfo';
import { useItem } from '@hooks/items/useItem';

import { isLandscape } from '@utils/dimensions';

import { Layout } from '@components/Layout';
import { ScreeLayout } from '@components/ScreeLayout';

export function Video({ navigation, route }: RouteAppProps<'Video'>) {
  const { colors, styles, infos } = useAllInfos();

  const [countPlay, setCountPlay] = useState(0);
  const [isInative, setIsInative] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const { id, fileUrl, title, description } = route.params;

  function onClose() {
    navigation.goBack();
  }

  function onFullScreen() {
    setIsFullScreen(true);
    videoRef.current?.requestFullscreen();
  }

  function onEnd() {
    window.document.exitFullscreen();
    if (countPlay > 0 && !isInative && !isFullScreen) {
      onClose();
    } else {
      setCountPlay(s => s + 1);
      setIsFullScreen(false);
    }
  }

  function onBack(isActive: boolean) {
    setIsInative(isActive);
  }

  useEffect(() => {
    if (infos?.videoMode === '1') onFullScreen();
  }, [infos]);

  return (
    <Layout onAction={onBack} timeForInactivity={5000} key={id}>
      <ScreeLayout
        itemFist={
          <Stack>
            <Flex
              borderRadius="2xl"
              bg={colors?.secondaryColor || 'gray.800'}
              minH="64"
              maxH={isLandscape(undefined, '72')}>
              <video
                ref={videoRef}
                src={fileUrl}
                autoPlay
                controls={false}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                  borderTopRightRadius: 15,
                  borderTopLeftRadius: 15,
                }}
                onEnded={onEnd}
              />
              <Text
                bg={colors?.tertiaryColor || 'darkBlue.800'}
                color={colors?.textColorButton || 'white'}
                lineHeight='sm'
                py="2"
                fontWeight="bold"
                position="absolute"
                bottom="0"
                w="100%"
                borderBottomRadius="xl"
                textAlign="center"
                onPress={onFullScreen}
              >
                Tela Cheia
              </Text>
            </Flex>
            <Button
              h="10"
              w="100%"
              borderRadius="xl"
              py="0"
              _text={{
                fontWeight: 'bold',
                color: colors?.textColorButton || undefined,
                fontSize: styles?.titleFontSize || undefined
              }}
              bg={colors?.tertiaryColor || 'darkBlue.800'}
              _hover={{ bg: colors?.tertiaryColor || 'darkBlue.800' }}
              colorScheme="black"
              _pressed={{ opacity: 0.5 }}
              onPress={onClose}>
              Voltar
            </Button>
          </Stack>
        }
        itemSecond={
          <Flex>
            <Button
              h="10"
              w={isLandscape('102%')}
              borderRadius="0"
              _text={{
                fontWeight: 'bold',
                color: colors?.textColorButton || undefined,
                fontSize: styles?.titleFontSize || undefined
              }}
              _dark={{ bg: colors?.tertiaryColor || 'black' }}
              isDisabled
              _disabled={{ opacity: 1 }}>
              {title}
            </Button>
            <Box
              h="10"
              w="104%"
              right="0"
              position="absolute"
              top="10"
              bg={{
                linearGradient: {
                  colors: ['gray.800', '#595959F0', '#59595955', '#5959590a'],
                },
              }}
              zIndex={1}
            />
            <ScrollView
              pt="10"
              pb="6"
              mb="2"
              showsVerticalScrollIndicator={false}>
              <Text
                color="black"
                fontSize="xl"
                fontFamily="Inter"
                textAlign="justify"
                lineHeight="lg"
                pb="4">
                {description}
              </Text>
            </ScrollView>
          </Flex>
        }
      />
    </Layout>
  );
}
