import { useQuery } from '@tanstack/react-query';

import { api } from '@services/api';

import { IError, ISuccess } from '@interfaces/IResponse';
import { IPagination } from '@interfaces/pagination';

interface IProps {
  name: string;
  schema: string;
  id: string;
}

interface IQuery {
  name: string;
}

async function ListCompanies(params: IQuery): Promise<IPagination<IProps>> {
  if (!params.name) {
    return { data: [], currentPage: 0, limit: 0, perPage: 0, total: 0 }
  }

  const { data } = await api.get<ISuccess<IPagination<IProps>>>('/companies', {
    params: { ...params, disabled: false },
  });

  return data.payload;
}

export function useCompanies(params: IQuery) {
  return useQuery<IPagination<IProps>, IError>(
    ['companies', params],
    () => ListCompanies(params),
    { staleTime: 1000 * 3 },
  );
}
