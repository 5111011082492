import { create } from 'zustand';

type IMenu = {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
};

export const useMenuStore = create<IMenu>(set => ({
  isOpen: false,
  onClose() {
    set({ isOpen: false });
  },
  onOpen() {
    set({ isOpen: true });
  },
}));
