import { useMutation } from '@tanstack/react-query';

import { api } from '@services/api';

import { IError } from '@interfaces/IResponse';

type Props = {
  type: 'first-click' | 'impression';
  itemId?: string;
  subType?: string;
};

export async function onNewEvent(data: Props): Promise<void> {
  await api.post('/metric/new-event', data);
}

function onError(error: IError) {
  console.log('############## Error: Impression ##############');
  console.log(JSON.stringify(error));
}

export function useNewEvent() {
  return useMutation(onNewEvent, { onError });
}
