import { create } from 'zustand';

interface IItem {
  value: any;
  inputId: string;
}

type IProps = {
  items: IItem[];
  isCheck: boolean;
  onChangeValue(
    id: string,
    i: string | number | boolean | string[] | null,
  ): void;
  onCheck(): void;
  onDestroy(): void;
};

export const useResponseStore = create<IProps>((set, _, { setState }) => ({
  items: [],
  isCheck: false,
  onChangeValue(id, i) {
    set(s => {
      const { items } = s;
      const is = items.findIndex(item => item.inputId === id);

      if (is === -1) items.push({ inputId: id, value: i });
      else items[is].value = i;

      return { items };
    });
  },
  onCheck() {
    set({ isCheck: true });
  },
  onDestroy() {
    setState(
      ({ onChangeValue, onDestroy, onCheck }) => ({
        onChangeValue,
        onDestroy,
        onCheck,
        items: [],
        isCheck: false,
      }),
      true,
    );
  },
}));
