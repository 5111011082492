import { Flex, Text, Image, Pressable } from 'native-base';

import { useAllInfos } from '@hooks/configs/useAllInfo';

interface IProps {
  id: string;
  title: string;
  fileUrl: string;
  onPressItem: (id: string) => void;
}

export function QueueCard({ id, title, onPressItem, fileUrl }: IProps) {
  const { colors, styles } = useAllInfos();

  return (
    <Pressable
      w="100%"
      maxW='xl'
      onPress={() => onPressItem(id)}
      style={({ pressed }) => ({ opacity: pressed ? 0.7 : 1 })}>
      <Flex
        h={fileUrl ? '24' : undefined}
        mx="2"
        w='100%'
        borderRadius="2xl"
        _dark={{ bg: colors?.secondaryColor || ' rgba(255, 255, 255, 0.18)' }}
        _light={{ bg: colors?.secondaryColor || 'gray.200' }}>
        {fileUrl && (
          <Image
            source={{ uri: fileUrl }}
            alt={title}
            style={{ height: '100%', borderRadius: 15 }}
          />
        )}
        <Text
          py={fileUrl ? '3' : styles?.btPY || '6'}
          px="4"
          w="100%"
          bottom="-2"
          borderBottomRadius="2xl"
          borderTopRadius={!fileUrl ? '2xl' : undefined}
          textAlign="center"
          fontWeight="bold"
          noOfLines={2}
          fontSize={styles?.btFontSize || undefined}
          color={colors?.textColorButton || 'white'}
          position={fileUrl ? 'absolute' : undefined}
          _dark={{ bg: colors?.tertiaryColor || 'black' }}
          _light={{ bg: colors?.tertiaryColor || 'darkBlue.800' }}>
          {title}
        </Text>
      </Flex>
    </Pressable>
  );
}
