import {
  FormControl,
  HStack,
  Radio,
  Stack,
  WarningOutlineIcon,
  Text,
} from 'native-base';
import React, { useMemo } from 'react';

import { useResponseStore } from '@stores/useResponseStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

import useDebounce from '@utils/useDebounce';
interface IProps {
  id: string;
  name: string;
  required: boolean;
  options: string[];
}

export function InputRadio({ id, name, required, options }: IProps) {
  const { items, onChangeValue, isCheck } = useResponseStore();

  const { colors } = useAllInfos();

  function onChange(value: string) {
    onChangeValue(id, value);
  }

  const item = useMemo(() => {
    return items.find(i => i.inputId === id);
  }, [items.length, id]);

  const isValidMessage = useMemo(() => {
    if (!isCheck) return undefined;

    if (required && !item?.value) {
      return 'Campo obrigatório';
    }

    return undefined;
  }, [item?.value, isCheck]);


  return (
    <FormControl isRequired={required} isInvalid={!!isValidMessage}>
      <Stack mx="4">
        <FormControl.Label
          _text={{ color: colors?.textColorSimple || 'gray.300' }}>
          {name}
        </FormControl.Label>
        <Radio.Group
          key={id}
          name={name}
          accessibilityLabel="favorite number"
          onChange={onChange}
          defaultValue={item?.value}
          _text={{
            color: 'red',
          }}>
          <HStack space="6">
            {options.map(option => (
              <Radio key={option} value={option} my={1}>
                <Text color={colors?.textColorSimple || 'gray.300'}>
                  {option}
                </Text>
              </Radio>
            ))}
          </HStack>
        </Radio.Group>
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {isValidMessage}
        </FormControl.ErrorMessage>
      </Stack>
    </FormControl>
  );
}
