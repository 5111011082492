import { Card } from "@components/Card";
import { ICard } from "@interfaces/ICard";
import { Box, FlatList } from "native-base"
import { useMemo, useState } from "react";

type IProps = {
  items: ICard[]
  onPressItem: (i: ICard) => void;
}

export function HomeScreen({ items, onPressItem }: IProps) {
  const [widthList, setWidthList] = useState(0);
  const [widthCard, setWidthCard] = useState(0);
  const [widthCardNew, setWidthCardNew] = useState(0);

  const numColumn = useMemo(() => {
    if (widthList > 0 && widthCard > 0) {
      const columns = Math.round(widthList / widthCard);
      const width = widthList / columns;

      setWidthCardNew(width);

      if (columns > 0 && !numColumn) {
        return columns;
      }
    }
  }, [widthList, widthCard]);

  return (
    <Box w="100%" h='88%'>
      {!numColumn && (
        <FlatList
          data={[{ id: 't1' }]}
          keyExtractor={item => item.id}
          renderItem={() => (
            <Box
              w="80"
              onLayout={({ nativeEvent }) => {
                if (widthCard) return;
                setWidthCard(nativeEvent.layout.width + 16);
              }}
            />
          )}
          onLayout={({ nativeEvent }) => {
            if (widthList) return;
            setWidthList(nativeEvent.layout.width);
          }}
        />
      )}
      {numColumn && (
        <FlatList
          data={items}
          showsVerticalScrollIndicator={false}
          numColumns={numColumn}
          keyExtractor={item => item.id}
          renderItem={({ item }) => (
            <Card
              title={String(item.title || item.name)}
              fileUrl={item.fileUrl}
              type={item.type}
              onPressItem={() => onPressItem(item)}
              w={widthCardNew}
              column={numColumn}
              cardUrl={item.cardUrl}
            />
          )}
          ItemSeparatorComponent={() => <Box h="15" />}
          ListFooterComponent={() => <Box h="32" />}
        />
      )}
    </Box>
  )
}