import React from 'react';

import { INpsModel } from '@interfaces/INpsModel';

import { Emoji } from './Emoji';
import { NPSMultipleSelect } from './NPSMultipleSelect';
import { NPSSelect } from './NPSSelect';
import { NpsText } from './NpsText';
import { StarTo1From3 } from './StarTo1From3';
import { StarTo1From5 } from './StarTo1From5';
import { To1From10 } from './To1From10';
import { To1From5 } from './To1From5';

export function NpsModel({ title, id, type, options }: INpsModel) {
  switch (type) {
    case 'number_10':
      return <To1From10 title={title} id={id} />;
    case 'number_5':
      return <To1From5 title={title} id={id} />;
    case 'star_3':
      return <StarTo1From3 title={title} id={id} />;
    case 'star_5':
      return <StarTo1From5 title={title} id={id} />;
    case 'emoji':
      return <Emoji title={title} id={id} />;
    case 'text':
      return <NpsText title={title} id={id} />;
    case 'select':
      return <NPSSelect title={title} id={id} options={options} />;
    case 'multiselect':
      return <NPSMultipleSelect title={title} id={id} options={options} />;

    default:
      return null;
  }
}
