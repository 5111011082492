import { Box, Heading, HStack } from 'native-base';
import React from 'react';
import { Pressable } from 'react-native';
import Icons from '@expo/vector-icons/FontAwesome5';

import { useNPSStore } from '@stores/useNPSStore';

interface IProps {
  id: string;
  title: string;
}

type INumber = {
  int: number;
  onPress: (i: number) => void;
  value: number;
};

function StarPress({ value, onPress, int }: INumber) {
  return (
    <Pressable onPress={() => onPress(int)}>
      <Icons name="star" size={60} solid={value >= int} color="white" />
    </Pressable>
  );
}

export function StarTo1From3({ id, title }: IProps) {
  const { items, onChangeValue } = useNPSStore();

  function onChange(i: number) {
    onChangeValue(id, i);
  }

  const item = items.find(i => i.npsModelId === id)?.value || 0;

  return (
    <Box>
      <Heading color="gray.200">{title}</Heading>
      <HStack space="4" mt="4">
        <StarPress onPress={onChange} int={1} value={item} />
        <StarPress onPress={onChange} int={2} value={item} />
        <StarPress onPress={onChange} int={3} value={item} />
      </HStack>
    </Box>
  );
}
