import {
  FormControl,
  Stack,
  WarningOutlineIcon,
  useColorMode,
} from 'native-base';
import React, { useMemo, useState } from 'react';
import DropDownPicker, { ValueType } from 'react-native-dropdown-picker';

import { useResponseStore } from '@stores/useResponseStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

import useDebounce from '@utils/useDebounce';

interface IProps {
  id: string;
  name: string;
  required: boolean;
  minLength?: number;
  maxLength?: number;
  options: string[];
}

export function InputMultipleSelect({
  id,
  name,
  required,
  maxLength,
  minLength,
  options,
}: IProps) {
  const { items: itemsStore, isCheck, onChangeValue } = useResponseStore();

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(options.map(i => ({ value: i })));

  const { colors } = useAllInfos();

  function onChange(value: any) {
    onChangeValue(id, value);
  }

  const item = useMemo(() => {
    return itemsStore.find(i => i.inputId === id);
  }, [itemsStore.length, id]);

  const [values, setValues] = useState(item?.value || []);

  const isValidMessage = useMemo(() => {
    if (!isCheck) return undefined;

    if (required && !item?.value?.length) {
      return 'Campo obrigatório';
    }

    if (minLength && item?.value?.length < minLength) {
      return `Selecione pelo menos ${minLength} items`;
    }

    if (maxLength && item?.value?.length > maxLength) {
      return `Selecione no máximo ${maxLength} items`;
    }

    return undefined;
  }, [item?.value, isCheck]);

  return (
    <FormControl isRequired={required} isInvalid={!!isValidMessage}>
      <Stack mx="4" h={open ? '64' : undefined}>
        <FormControl.Label
          _text={{ color: colors?.textColorSimple || 'gray.300' }}>
          {name}
        </FormControl.Label>
        <DropDownPicker
          key={id}
          items={items}
          schema={{ label: 'value' }}
          open={open}
          setOpen={setOpen}
          setValue={setValues}
          onChangeValue={onChange}
          setItems={setItems}
          value={values}
          multiple
          min={minLength || 0}
          max={maxLength || undefined}
          mode="BADGE"
          placeholder=""
          listMode="SCROLLVIEW"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderBottomColor: '#505050',
            borderBottomWidth: 2,
            borderRadius: 0,
            height: 50,
          }}
          dropDownContainerStyle={{
            height: 200,
            position: 'relative',
            top: 0,
            marginBottom: 10,
            backgroundColor: '#515151',
          }}
          badgeProps={{
            style: {
              backgroundColor: '#505050',
              paddingHorizontal: 20,
              borderRadius: 100,
              paddingVertical: 5,
            },
          }}
          badgeTextStyle={{ color: '#F3F3F3' }}
          badgeDotStyle={{ display: 'none' }}
          listParentLabelStyle={{ textAlign: 'center', fontWeight: 'bold' }}
          listItemContainerStyle={{
            borderTopColor: '#C4C4C4',
            borderTopWidth: 2,
            height: 60,
          }}
          textStyle={{ color: '#F7F7F7', fontSize: 20 }}
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {isValidMessage}
        </FormControl.ErrorMessage>
      </Stack>
    </FormControl>
  );
}
