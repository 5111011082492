import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { INps } from '@interfaces/INps';
import { IError, ISuccess } from '@interfaces/IResponse';

async function list(id: string): Promise<INps> {
  const { data } = await api.get<ISuccess<INps>>(
    `/${authStore.getState().schema}/nps/item/${id}`,
  );

  return data.payload;
}

export function useNpsItem(item: string) {
  return useQuery<INps, IError>(['Nps-Item', item], () => list(item), {
    staleTime: 1000 * 60 * 10,
  });
}
