import { IError } from '@interfaces/IResponse';

export class AppError {
  public readonly statusCode: number;

  public readonly message: string;

  public readonly error?: string | object;

  constructor(err: IError) {
    this.statusCode = err?.statusCode;
    this.message = err?.message;
    this.error = err?.error;
  }
}
