import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { Form } from '@screens/Form';
import { Home } from '@screens/Home';
import { Info } from '@screens/Info';
import { LinkWeb } from '@screens/LinkWeb';
import { Queue } from '@screens/Queue';
import { Video } from '@screens/Video';

import { AppParamList } from './types';
import { Nps } from '@screens/Nps';

const AppStack = createNativeStackNavigator<AppParamList>();

export function AppRoutes() {
  return (
    <AppStack.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false }}>
      <AppStack.Screen name="Home" component={Home} />
      <AppStack.Screen name="Nps" component={Nps} />
      <AppStack.Screen name="Info" component={Info} />
      <AppStack.Screen name="Video" component={Video} />
      <AppStack.Screen name="Form" component={Form} />
      <AppStack.Screen name="Queue" component={Queue} />
    </AppStack.Navigator>
  );
}
