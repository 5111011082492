import {
  Stack,
  Button,
  KeyboardAvoidingView,
  Input,
  Flex,
  ScrollView,
  View,
  Heading,
} from 'native-base';
import React, { useRef, useState } from 'react';

import { RouteAuthProps } from '@routes/types';

import { useSingIn } from '@hooks/auth/useSingIn';

import useDebounce from '@utils/useDebounce';

export function SingIn({ route }: RouteAuthProps<'SignIn'>) {
  const [email, sendEmail] = useState('');
  const [password, sendPassword] = useState('');

  const emailDebounce = useDebounce(sendEmail);
  const passwordDebounce = useDebounce(sendPassword);

  const passwordRef = useRef<any>(null);

  const onLogin = useSingIn();

  const {
    params: { name, schema },
  } = route;

  const emailExp =
    /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;

  function validate() {
    return emailExp.test(email) && password.length > 1;
  }

  async function handleSubmit() {
    onLogin.mutateAsync({ email, password, schema });
  }

  return (
    <View bg='darkBlue.900' flex="1">
      <ScrollView showsVerticalScrollIndicator={false}>
        <KeyboardAvoidingView>
          <Flex align="center" pt="24" paddingBottom={2}>
            <Heading fontSize="3xl" color="gray.300">{name}</Heading>
            <Stack w="90%" maxWidth="390px" mt="10" space="8">
              <Input
                placeholder="Email"
                autoComplete="email"
                returnKeyType="next"
                defaultValue={email}
                onChangeText={emailDebounce}
                onSubmitEditing={() => passwordRef.current.focus()}
                disableFullscreenUI
                color="gray.200"
              />
              <Input
                type="password"
                placeholder="Senha"
                returnKeyType="send"
                defaultValue={password}
                onChangeText={passwordDebounce}
                ref={passwordRef}
                onSubmitEditing={handleSubmit}
                disableFullscreenUI
                color="gray.200"
              />
              <Button
                onPress={handleSubmit}
                bg="red.600"
                colorScheme="red"
                isLoading={onLogin.isLoading}
                isDisabled={!validate()}
                color="#F3F3F3"
                size="lg"
                mt="4"
                variant="solid"
                borderRadius="9px"
                h="50px"
                shadow="9"
                fontWeight={500}>
                Entrar
              </Button>
            </Stack>
          </Flex>
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}
