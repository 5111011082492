import { useMutation } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { useAllInfos } from '@hooks/configs/useAllInfo';

import { showToast } from '@utils/showToast';

import { IError } from '@interfaces/IResponse';

type IDataResponse = {
  npsModelId: string;
  value?: number;
  text?: string;
  texts?: string[];
}[];

type IProps = {
  data: IDataResponse;
  npsId: string;
};

async function callApi({ data, npsId }: IProps): Promise<void> {
  const newData = data.map(({ npsModelId, text, texts, value }) => ({
    npsModelId,
    value,
    text: text || JSON.stringify(texts),
  }));

  await api.post(`/${authStore.getState().schema}/nps/answer`, {
    npsId,
    data: newData,
  });
}

function onError(error: IError) {
  console.log(error);

  showToast({
    title: 'Erro ao enviar avaliação',
    message: 'Tente novamente mais tarde',
    type: 'error',
  });
}

export function useCreateNpsAnswer() {
  const { infos } = useAllInfos();

  return useMutation(callApi, {
    onSuccess: () => {
      showToast({
        title: infos.npsTitle || 'Avaliação enviada',
        message: infos.npsDescription || 'Obrigado por participar!',
      });
    },
    onError,
  });
}
