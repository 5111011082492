import { FormControl, Stack, TextArea, WarningOutlineIcon } from 'native-base';
import React, { useMemo } from 'react';

import { useResponseStore } from '@stores/useResponseStore';

import { useAllInfos } from '@hooks/configs/useAllInfo';

import useDebounce from '@utils/useDebounce';
import { KeyboardArea } from '@components/Keyboard/KeyboardArea';

interface IProps {
  id: string;
  name: string;
  minLength?: number;
  maxLength?: number;
  required: boolean;
}

export function InputArea({
  id,
  name,
  required,
  maxLength,
  minLength,
}: IProps) {
  const { items, onChangeValue, isCheck } = useResponseStore();

  const valueDebounce = useDebounce(onChangeValue);
  const { colors } = useAllInfos();

  function onChange(value: string) {
    valueDebounce(id, value);
  }

  const item = useMemo(() => {
    return items.find(i => i.inputId === id);
  }, [items.length, id]);

  const isValidMessage = useMemo(() => {
    if (!isCheck) return undefined;

    if (required && !item?.value) {
      return 'Campo obrigatório';
    }

    if (minLength && item?.value?.length < minLength) {
      return `Mínimo ${minLength} caracteres`;
    }

    if (maxLength && item?.value?.length > maxLength) {
      return `Máximo ${maxLength} caracteres`;
    }

    return undefined;
  }, [item?.value, isCheck]);

  return (
    <FormControl isRequired={required} isInvalid={!!isValidMessage}>
      <Stack mx="4">
        <FormControl.Label
          _text={{ color: colors?.textColorSimple || 'gray.300' }}>
          {name}
        </FormControl.Label>
        <KeyboardArea
          name={name}
          onchangeText={onChange}
          value={item?.value}
          key={id}
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {isValidMessage}
        </FormControl.ErrorMessage>
      </Stack>
    </FormControl>
  );
}
