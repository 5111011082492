import { Box, Button, Heading, Modal, TextArea } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';

import Keyboard from 'react-simple-keyboard';



type IProps = {
  onchangeText: (i: string) => void;
  value?: string;
  name: string;
}

export function KeyboardArea({ onchangeText, value, name }: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState('');
  const [layoutName, setLayoutName] = useState('default');
  const keyboard = useRef<any>();


  function onClose() {
    setIsOpen(false);
  }

  function onKeyPress(i: string) {
    if (i === "{shift}" || i === "{lock}") setLayoutName(layoutName === "default" ? "shift" : "default");
  }

  function onChange(i: string) {
    setInput(i);
  }

  function onChangeInput(event: any) {
    const input = event.target.value;
    setInput(input);
    keyboard?.current?.setInput(input);
  };

  function onSave() {
    onchangeText(input);
    onClose();
  }

  useEffect(() => {
    if (isOpen && value) setInput(value);
  }, [isOpen]);

  return (
    <Box>
      <Pressable onPress={() => setIsOpen(true)}>
        <TextArea
          autoCompleteType="cc-csc"
          value={value}
          editable={false}
          color={"gray.200"}
        />
      </Pressable>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <Modal.Content w="90%">
          <Modal.CloseButton />
          <Modal.Header>
            <Heading>{name}</Heading>
          </Modal.Header>
          <Modal.Body>
            <TextArea
              autoCompleteType="cc-csc"
              value={input}
              onChange={onChangeInput}
              bg="gray.200"
            />
            {isOpen && <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              display={{
                '{bksp}': '←',
                '{enter}': '↩',
                '{shift}': 'shift',
                '{space}': ' '
              }}
              layout={{
                default: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'q w e r t y u i o p',
                  'a s d f g h j k l ç {enter}',
                  '{shift} z x c v b n m , . {shift}',
                  '@ {space}'
                ],
                shift: [
                  '! @ # $ % ^ & * ( ) {bksp}',
                  'Q W E R T Y U I O P',
                  'A S D F G H J K L Ç {enter}',
                  '{shift} Z X C V B N M / ? {shift}',
                  '@ {space}'
                ]
              }}
              layoutName={layoutName}
              onChange={onChange}
              onKeyPress={onKeyPress}
              newLineOnEnter
            />}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={onClose} colorScheme="danger" px="12">
              Fechar
            </Button>
            <Button onPress={onSave} colorScheme="success" px="12" ml='4'>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
}
