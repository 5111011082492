import { Center, Image as Image2 } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';


import { useAllFiles } from '@hooks/items/useAllFiles';

import { height, width } from '@utils/dimensions';
import { time1Second } from '@utils/times';

interface IProps {
  images: string[];
}

let count = 1;

export function Image({ images }: IProps) {
  const [uri, setUri] = useState<string>();
  const { data } = useAllFiles();

  useEffect(() => {
    if (uri === undefined && images.length > 0) {
      setUri(() => images[0]);
    }
  }, [images]);

  const newImages = useMemo(() => {
    if (!data) return images;

    return [...images, ...data];
  }, [data, images]);

  function onChange() {
    if (newImages.length === 1) return;

    if (newImages.length > count) count += 1;
    else count = 1;

    setUri(() => newImages[count - 1]);
  }

  useEffect(() => {
    setTimeout(() => onChange(), 10 * time1Second);
  }, [uri, newImages]);

  return (
    <Center
      w="100%"
      h="100%"
      position="absolute"
      paddingY="5"
      bg="black"
      opacity="0.96">
      {uri && (
        <Image2
          source={{ uri }}
          style={{
            height: height * 0.95,
            width: width * 0.95,
            borderRadius: 15,
          }}
          resizeMode="contain"
        />
      )}
    </Center>
  );
}
