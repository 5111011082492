
import { io, Socket } from 'socket.io-client';

import { authStore } from '@stores/useAuthStore';

import { time5Minutes } from '@utils/times';

import { IIoRefeche } from '@interfaces/IIoRefeche';

import { queryClient } from './queryClient';
import { URLS } from '@utils/urls';

let socketRef: Socket;

export function socketIo() {
  if (socketRef) return socketRef;

  const { token, schema } = authStore.getState();

  const socket = io(URLS.URL_IO, {
    auth: { schema, origin: 'app' },
    extraHeaders: { Authorization: token as string },
    path: URLS.IO_PATH,
  });

  socket.on('connect_error', async err => {
    socket.disconnect();
    console.log('connect_error:', err);

    setTimeout(() => {
      console.log('reconnecting');
      socket.connect();
    }, time5Minutes * 3);
  });

  socket.on('sign-out', () => {
    authStore.getState().onOutLogin();
    socket.disconnect();
  });

  socket.on('close-app', () => {
    socket.disconnect();
  });

  socket.on('refeche', (item: IIoRefeche) => {
    console.log('refeche', item);
    queryClient.invalidateQueries([item.type]);
  });

  socketRef = socket;
  return socket;
}
