import { useEffect } from 'react';

import { useAuthStore } from '@stores/useAuthStore';

import { AuthRoutes } from './auth.routes';
import { AppRoutes } from './app.routes';

export function Routes() {
  const { token } = useAuthStore();

  return token ? <AppRoutes /> : <AuthRoutes />;
}
