import { useQuery } from '@tanstack/react-query';

import { authStore } from '@stores/useAuthStore';

import { api } from '@services/api';

import { IAllInfos } from '@interfaces/configs/IAllInfos';
import { IError, ISuccess } from '@interfaces/IResponse';

export async function findAllInfos(): Promise<IAllInfos> {
  if (!authStore.getState().schema) {
    throw new Error('Authorization is not defined');
  }

  const { data } = await api.get<ISuccess<IAllInfos>>(
    `/${authStore.getState().schema}/me`,
  );

  return data.payload;
}

export function useAllInfos() {
  const { data } = useQuery<IAllInfos, IError>(['All-Info'], findAllInfos, {
    staleTime: 1000 * 60 * 10,
  });

  return (data || {}) as IAllInfos;
}
