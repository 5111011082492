import { create, useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import { getCurrentTime } from '@utils/getCurrentTime';
import { time60Seconds } from '@utils/times';

type ITime = {
  time: string;
  initial: boolean;
  initTime(): void;
};

export const timeStore = createStore<ITime>((set, stt) => ({
  time: '00:00',
  initial: false,
  initTime: () => {
    const initial = stt().initial;
    if (initial) return;
    set({ time: getCurrentTime(), initial: true });
    setInterval(() => {
      set({ time: getCurrentTime() });
    }, time60Seconds);
  },
}));

timeStore.getState().initTime();

export function useTimeStore() {
  return useStore(timeStore);
}
